<template>
  <div class="addToCardBox">
    <slot v-if="isHasSlotTop" name="slotTop" />
    <GroupGameInfoPayment
      class="d-none d-lg-block w-100 mb-3"
      :totalPrice="totalPrice"
      :fee="transactionFee"
      :resultPrice="resultPrice"
    >
    </GroupGameInfoPayment>

    <slot />
    <div class="d-flex d-lg-none justify-content-between mb-2 pb-1">
      <div class="d-flex align-items-center">
        <span class="addToCardBox__priceTitle">Tổng cộng: </span>
        <span class="addToCardBox__priceValue">{{ formatPrice(resultPrice) }}</span>
      </div>
      <div class="d-flex align-items-center">
        <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.DEPOSIT_BALANCE">
          <div v-if="accountId" class="totalBalance">
            Số dư TK: <span>{{ formatPrice(depositBalance + pendingBalance) }}</span>
          </div>
        </ExcludeFeatureWrapper>
      </div>
    </div>
    <div v-if="isPaymentIntegration" class="addToCardBox__action">
      <button
        :disabled="
          loggedIn ? (isLoading && !lastClickAddCart) || (isDisabled && isBuyNow) : isDisabled || isDisabledQuickBuy
        "
        class="btn w-100 addToCardBox__action--buy mr-0"
        @click="handleBuyNowInPaymentIntegration"
      >
        <div v-if="isLoading && !lastClickAddCart" class="text-center w-100">
          <b-spinner small></b-spinner>
        </div>
        <span v-else>{{ isBuyNow ? buyNowText : 'Xem giỏ hàng' }}</span>
      </button>
      <p v-if="!hiddenQuickBuy" class="font-14 mt-1"><i>Quét mã, thanh toán ngay, không cần đăng nhập</i></p>
    </div>

    <div v-else class="addToCardBox__action">
      <div class="d-flex">
        <button
          v-if="!isHideAddToCartByExclude && !isHideAddtoCardBtn"
          :disabled="(isLoading && lastClickAddCart) || isDisabled"
          class="btn addToCardBox__action--add"
          @click="addToCart"
          :class="{
            disablePlayTrial8: isPlayTrialModeMobile
          }"
        >
          <div
            v-if="isLoading && lastClickAddCart"
            class="text-center w-100 d-flex justify-content-center align-items-center h-100"
          >
            <b-spinner small></b-spinner>
          </div>
          <span v-else>
            <span
              v-if="stateAddedCart"
              class="text-uppercase  w-100 color-yellow d-flex align-items-center justify-content-center addToCardBox__action__textAdded"
            >
              <img src="@/assets/img/icon/icon_checkdone.svg" width="20" height="20" />
            </span>
            <span v-else class="groupCartIcon">
              <img src="@/assets/img/icon_addcart.svg" alt="icon_add_to_cart" width="22px" height="21px" />
            </span>
          </span>
          <img
            id="iconCartFrom"
            :src="gameImg ? gameImg : '@/assets/img/icon_addcart.svg'"
            alt="icon_add_to_cart"
            width="22px"
            height="21px"
            class="imgGame"
            :class="{
              active: runAnimateAddToCart
            }"
          />
        </button>
        <Tooltip
          v-if="isPlaytrial && isBuyNow"
          dataStep="choose_buy_now"
          content="Nhấn nút <b>Mua Vé</b> để tiến hành thanh toán cho cho các bộ số đã chọn."
          position="top"
        >
          <button
            :disabled="(isLoading && !lastClickAddCart) || (isDisabled && isBuyNow)"
            class="btn w-100 addToCardBox__action--buy"
            :class="{
              'mr-0': isHideAddToCartByExclude || isHideAddtoCardBtn
            }"
            @click="buyNow"
          >
            <div v-if="isLoading && !lastClickAddCart" class="text-center w-100">
              <b-spinner small></b-spinner>
            </div>
            <span v-else>{{ buyNowText }}</span>
          </button>
        </Tooltip>
        <button
          v-else
          :disabled="(isLoading && !lastClickAddCart) || (isDisabled && isBuyNow)"
          class="btn w-100 addToCardBox__action--buy"
          :class="{
            'mr-0': isHideAddToCartByExclude || isHideAddtoCardBtn
          }"
          @click="buyNow"
        >
          <div v-if="isLoading && !lastClickAddCart" class="text-center w-100">
            <b-spinner small></b-spinner>
          </div>
          <span v-else>{{ isBuyNow ? buyNowText : 'Xem giỏ hàng' }}</span>
        </button>
      </div>
      <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.QUICK_BUY" class="w-100">
        <button
          v-if="!hiddenQuickBuy"
          class="btn w-100 addToCardBox__action--quickBuy mt-2"
          @click="handleQuickBuy"
          :disabled="isDisabled || isDisabledQuickBuy"
        >
          <div>
            <!-- icon_muasieutoc.svg -->
            <svg class="dropShadow mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g transform="translate(13970 19621)">
                <rect width="24" height="24" transform="translate(-13970 -19621)" fill="#fff" opacity="0" />
                <path
                  d="M1.071,24a5.483,5.483,0,0,1,.56-1.575q.638-1.5,1.431-3.305t1.457-3.37a6.724,6.724,0,0,0,.612-1.718q-.052-.13-.833-.468t-1.7-.729q-.924-.39-1.7-.768T0,11.4q-.078-.26,1.379-2.108t3.253-3.93Q6.432,3.283,7.968,1.6T9.607,0a5.657,5.657,0,0,1-.56,1.6Q8.41,3.126,7.616,4.948T6.159,8.305a6.8,6.8,0,0,0-.612,1.666,2.709,2.709,0,0,0,.833.429l1.718.716q.937.39,1.731.794t.846.69q.052.286-1.405,2.134T6.029,18.65q-1.783,2.069-3.318,3.735A9.248,9.248,0,0,1,1.071,24Z"
                  transform="translate(-13963 -19621)"
                  fill="#fff"
                />
              </g>
            </svg>
            <span>Mua siêu tốc</span>
          </div>
        </button>
        <p v-if="!hiddenQuickBuy" class="font-14 mt-1"><i>Quét mã, thanh toán ngay, không cần đăng nhập</i></p>
      </ExcludeFeatureWrapper>
    </div>
  </div>
</template>

<script>
import GroupGameInfoPayment from './GroupGameInfoPayment';
import Tooltip from '@/components/UI/Tooltip';
import { mapGetters } from 'vuex';

export default {
  components: {
    GroupGameInfoPayment,
    Tooltip
  },
  props: {
    totalPrice: {
      type: Number,
      default: 0
    },
    transactionFee: {
      type: Number,
      default: 0
    },
    resultPrice: {
      type: Number,
      default: 0
    },
    isBuyNow: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    stateAddedCart: {
      type: Boolean,
      default: false
    },
    lastClickAddCart: {
      type: Boolean,
      default: false
    },
    gameId: {
      type: Number
    },
    isDisabled: {
      type: Boolean
    },
    isHideAddtoCardBtn: {
      type: Boolean,
      default: false
    },
    hiddenQuickBuy: {
      type: Boolean
    },
    isDisabledQuickBuy: {
      type: Boolean
    },
    buyNowText: {
      type: String,
      default: 'Mua vé'
    }
  },
  data() {
    return {
      runAnimateAddToCart: false
    };
  },
  watch: {
    stateAddedCart: function(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.runAnimationAddToCart();
      }
    }
  },
  computed: {
    ...mapGetters('partner', ['getterExcludeFeature']),
    ...mapGetters(['getterGlobalExcludeFeature']),
    isHideAddToCartByExclude() {
      // console.log(this.getterExcludeFeature, this.getterGlobalExcludeFeature);
      return (
        this.getterExcludeFeature?.includes(this.FEATURE_HANDLER.CART) ||
        this.getterGlobalExcludeFeature?.includes(this.FEATURE_HANDLER.CART)
      );
    },
    listGame() {
      return this.$store.getters.getterListGame;
    },
    gameImg() {
      return this.listGame[this.gameId]?.image_url || '';
    },
    isPlaytrial() {
      return this.$store.state.playTrial && this.$store.getters.getterLoggedIn;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    isHasSlotTop() {
      return !!this.$slots['slotTop'];
    }
  },
  methods: {
    addToCart: function() {
      if (this.isPlayTrialModeMobile) {
        this.notiPlayTrial();
        return;
      }

      this.$emit('addToCart');
      this.$emit('updateLastClickAddCart', true);
    },
    buyNow: function() {
      this.$emit('buyNow');
      this.$emit('updateLastClickAddCart', false);
    },
    runAnimationAddToCart: function() {
      if (this.runAnimateAddToCart) return;
      this.runAnimateAddToCart = true;

      const iconCartFrom = document.getElementById('iconCartFrom');
      const iconCartTos = document.querySelectorAll('.iconCartTo');
      const controlBox = document.querySelector('.controlBox');
      controlBox.style.zIndex = 1500;

      let dataCartTo = {
        x: 0,
        y: 0
      };
      if (iconCartTos && iconCartTos.length > 0) {
        let result = 0;
        iconCartTos.forEach((item) => {
          const { x, y } = item.getBoundingClientRect();
          if (x > result) {
            result = x;
            dataCartTo.x = x;
            dataCartTo.y = y;
          }
        });
      }
      const { x: xFrom, y: yFrom } = iconCartFrom.getBoundingClientRect();

      const left = Math.abs(dataCartTo.x - xFrom) + 30;
      const bottom = Math.abs(dataCartTo.y - yFrom);
      iconCartFrom.style.left = left + 'px';
      iconCartFrom.style.bottom = bottom + 'px';
      setTimeout(() => {
        this.runAnimateAddToCart = false;
        iconCartFrom.style.left = 0 + '50%';
        iconCartFrom.style.bottom = 0 + '50%';
        controlBox.style.zIndex = 999;
      }, 1350);
    },
    handleQuickBuy() {
      this.$emit('quickBuy');
    },
    handleBuyNowInPaymentIntegration() {
      if (this.loggedIn) {
        this.buyNow();
      } else {
        this.handleQuickBuy();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.addToCardBox {
  &__priceTitle {
    color: #333333;
    margin-right: 6px;
    font-size: 13px;
  }
  &__priceValue {
    font-size: 1rem;
    color: $primaryColor;
    font-weight: 700;
    line-height: 1.2;
  }

  &__action {
    // display: flex;

    a {
      width: 100%;
    }
    button {
      text-transform: uppercase;
      border-radius: 3px;
      font-weight: 600;
      min-height: 40px;
      font-size: 0.9375rem;

      &:first-child {
        margin-right: 10px;
      }

      @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        min-height: 50px;
      }
    }

    &--add {
      position: relative;
      background-color: #f7f7f7;
      min-width: 70px;
      &:hover {
        background-color: darken(#f7f7f7, 10%);
      }
    }

    &--buy {
      color: #fff;
      background-color: $primaryColor;
    }

    &--quickBuy {
      color: #fff;
      background-image: linear-gradient(285deg, #fff0ba -130%, #ffbd0a);
    }

    &__textAdded {
      font-size: 0.9375rem;
    }
  }

  .imgGame {
    position: absolute;
    bottom: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, 50%);

    &.active {
      /**
          option 1
            transition: 1.5s all cubic-bezier(0.73, -0.06, 0.83, 0.32);
          option 2
            transition: 1.5s all cubic-bezier(0.73, -0.2, 0.26, 1.24);
          option 3
            transition: 1.5s all cubic-bezier(0.19, 0.45, 1, 0.69);
          option 4
            transition: 1.5s all cubic-bezier(0.07, 0.55, 1, 0.46);
         */
      transition: 1.3s all cubic-bezier(0.73, -0.2, 0.26, 1.24);
      animation: fromTo 1.3s ease-in;
    }
  }

  @keyframes fromTo {
    0% {
      opacity: 0.5;
      transform: scale(0.5);
    }
    25% {
      opacity: 0.8;
      transform: scale(1.5);
    }
    50% {
      opacity: 1;
      transform: scale(3);
    }
    70% {
      transform: scale(2.5);
    }

    95% {
      opacity: 1;
    }
    99% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
      transform: scale(1);
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    .addToCardBox {
      &__action {
        button {
          font-size: 1.25rem;
        }

        &__textAdded {
          font-size: 1.25rem;
        }

        &--add {
          min-width: 80px;
        }
      }

      &__priceValue {
        font-size: 1.875rem;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .addToCardBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__action {
      display: flex;
      flex: 0 0 60%;
      flex-direction: column;
      button {
        font-size: 0.9375rem;

        &:first-child {
          margin-bottom: 0;
        }
      }

      &__textAdded {
        font-size: 1.25rem;
      }
    }
    &__priceTitle {
      font-size: 0.8125rem;
    }

    &__priceValue {
      font-size: 1.5625rem;
      margin-bottom: 0;
    }
  }
}
</style>
