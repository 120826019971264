<template>
  <div :class="customClass">
    <div v-if="true" class="container pt-10 pb-10">
      <UIGameHeaderDetail :tutorialGame="tutorialGame" :gameTitle="gameTitle" />
      <slot />
      <div v-if="gameStatus" class="row">
        <div
          class="col-12"
          :class="{
            'col-lg-8': asideRight
          }"
        >
          <div class="contentBox">
            <div v-if="isLoading">
              <content-placeholders :rounded="true">
                <content-placeholders-img class="placeholders-listGame" />
              </content-placeholders>
            </div>
            <slot v-else name="asideLeft" />
            <div id="bottomLayout"></div>
          </div>
        </div>
        <aside v-if="asideRight" class="col-12 col-lg-4">
          <div class="controlBox globalBorder">
            <div v-if="isLoading">
              <content-placeholders :rounded="true">
                <content-placeholders-img class="placeholders-listGame" />
              </content-placeholders>
            </div>
            <slot v-else name="asideRight" />
            <div v-if="isPlayTrial && isShowOverlay" class="controlBox__overlay"></div>
          </div>
        </aside>
      </div>
      <div v-else>
        <slot name="maintaince" />
      </div>
    </div>
  </div>
</template>

<script>
import UIGameHeaderDetail from '../../components/UI/Game/UIGameHeaderDetail';

export default {
  components: {
    UIGameHeaderDetail
  },
  props: {
    gameStatus: {
      type: Boolean
    },
    gameTitle: {
      type: String,
      default: '---'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    tutorialGame: {
      type: String
    },
    asideRight: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: [String, Object],
      default: ''
    }
  },
  computed: {
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    },
    isPlayTrial() {
      return this.$store.getters.getterPlayTrial;
    },
    isShowOverlay() {
      return this.$store.getters.getterShowOverlay;
    }
  },
  methods: {
    scrollToBottom: function() {
      document.getElementById('bottomLayout').scrollIntoView();
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  color: $primaryColor;
  font-weight: 600;
}

.contentBox {
  padding-bottom: 13rem;

  overflow-x: hidden;
}

.controlBox {
  padding: 15px;
  text-align: center;

  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;

  // box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  // z-index: 1025;
  z-index: 1030;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    opacity: 0.2;
    background-color: #000000;
  }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .controlBox {
    padding: 0.85rem 1rem;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .contentBox {
    padding-bottom: 4rem;
  }

  .controlBox {
    position: sticky;
    top: 5rem;
    padding: 20px;
    z-index: 1020;
  }
}
</style>
