var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[(_vm.chooseListLength)?_c('div',_vm._l((Object.values(_vm.chooseItemListFormat)),function(data,index){return _c('div',{key:data.productId,staticClass:"chooseItemList"},[_c('div',{staticClass:"chooseItemList__productName",class:{
          'mt-0': index === 0
        }},[_vm._v(" "+_vm._s(data.productName)+" ")]),_vm._l((data.ticketList),function(ticket){return _c('div',{key:ticket.id,staticClass:"chooseItemList__ticket",class:{
          outOfStock: !ticket.quantity
        },on:{"click":function($event){return _vm.$emit('handleClickItem', ticket, !!ticket.value)}}},[(!ticket.value)?_c('div',[_c('div',{staticClass:"chooseItemList__ticket__info"},[_c('div',[_c('span',{staticClass:"font-18 font-weight-600"},[_vm._v(" "+_vm._s(ticket.ticket_info)+" ")]),_c('span',{staticClass:"font-13"},[_vm._v(" ("),_c('strong',{class:{
                    'color-red-1': ticket.isChanged
                  }},[_vm._v(_vm._s(ticket.quantity))]),_vm._v(" vé) ")])]),_c('div',{staticClass:"font-13"},[_c('em',[_vm._v(" "+_vm._s(_vm.getDay(ticket.lotteryTime))+" , ngày "+_vm._s(_vm.formatDate(ticket.lotteryTime))+" ")])]),_c('div',{staticClass:"font-13"},[(!ticket.quantity)?_c('em',{staticClass:"color-red-1"},[_vm._v(" Vé bạn chọn đã bán hết ")]):(ticket.isChanged)?_c('em',{staticClass:"color-red-1"},[_vm._v(" Số lượng vé bạn chọn đã thay đổi ")]):_vm._e()])]),_c('div',{staticClass:"chooseItemList__ticket__action",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleRemove', ticket.id)}}},[_c('img',{attrs:{"src":require("@/assets/img/img_trash.png"),"alt":"no ticket"}})])]):_c('div',[_c('div',{staticClass:"chooseItemList__ticket__info"},[_c('div',[_c('span',{staticClass:"font-18 font-weight-600"},[_c('span',[_c('span',{staticClass:"color-gray"},[_vm._v("x")]),_vm._v(_vm._s(ticket.value.slice(1)))])]),_c('span',{staticClass:"font-13"},[_vm._v(" (Cặp nguyên, "),_c('strong',{class:{
                    'color-red-1': ticket.isChanged
                  }},[_vm._v(_vm._s(ticket.quantity))]),_vm._v(" vé) ")])]),_c('div',{staticClass:"font-13"},[_c('em',[_vm._v(" "+_vm._s(_vm.getDay(ticket.lotteryTime))+" , ngày "+_vm._s(_vm.formatDate(ticket.lotteryTime))+" ")])]),_c('div',{staticClass:"font-13"},[(!ticket.quantity)?_c('em',{staticClass:"color-red-1"},[_vm._v(" Vé bạn chọn đã bán hết ")]):(ticket.isChanged)?_c('em',{staticClass:"color-red-1"},[_vm._v(" Số lượng vé bạn chọn đã thay đổi ")]):_vm._e()])]),_c('div',{staticClass:"chooseItemList__ticket__action",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleRemove', ticket.id)}}},[_c('img',{attrs:{"draggable":false,"src":require("@/assets/img/img_trash.png"),"alt":"no ticket"}})])])])})],2)}),0):_c('div',{staticClass:"text-center pt-3"},[_c('img',{attrs:{"draggable":false,"src":require("@/assets/img/image_no_ticket.png"),"alt":"no ticket"}}),_c('div',[_vm._v("Bạn chưa chọn vé")])])])}
var staticRenderFns = []

export { render, staticRenderFns }