<template>
  <div class="groupInfo">
    <p class="groupInfo__price">
      <span>
        {{ moneyText }}
      </span>
      <span>{{ this.formatPrice(totalPrice) }}</span>
    </p>
    <p class="groupInfo__fee">
      <span>
        Phí giao dịch
      </span>
      <span>{{ this.formatPrice(fee) }}</span>
    </p>
    <p v-if="!isHiddenResult" class="groupInfo__resultPrice">
      <span>
        Tổng cộng
      </span>
      <span class="m-text-primary font-18 font-weight-bold">{{ this.formatPrice(resultPrice) }}</span>
    </p>
    <div v-if="!isHiddenResult" class="groupInfo__resultPrice">
      <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.DEPOSIT_BALANCE">
        <div v-if="accountId" class="d-none d-lg-block totalBalance text-left font-14">
          Số dư TK: <span>{{ formatPrice(depositBalance + pendingBalance) }}</span>
        </div>
      </ExcludeFeatureWrapper>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    totalPrice: Number,
    fee: Number,
    resultPrice: Number,
    isHiddenResult: Boolean,
    moneyText: {
      type: String,
      default: 'Tiền vé'
    }
  }
};
</script>

<style lang="scss" scoped>
.groupInfo {
  & > p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    & > span:nth-child(2) {
      font-weight: 600;
    }
  }
}
</style>
