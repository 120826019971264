<template>
  <b-modal
    headerClass="border-bottom-0"
    footerClass="border-top-0 pt-0"
    body-class="pt-0"
    no-close-on-backdrop
    no-close-on-esc
    @hide="hideModal"
    v-model="modalChooseNumberItemData"
    scrollable
    centered
    :modal-class="{
      modalChooseItemNumber: true,
      modalCustomMobileSecond: true,
      pt40: isHeaderPlaytrial
    }"
  >
    <template v-slot:modal-title>
      <p class="font-20 color-black-1">{{ titleModal }}</p>
    </template>
    <p class="font-15 color-black-1">{{ title }}</p>
    <Tooltip
      dataStep="choose_game_number_in_modal"
      isBackgroundChild
      hasUnderstood
      @handleUnderstood="handleNextStep"
      buttonText="Tiếp tục"
      tooltipContentClass="tooltipPlayTrial mt-5"
      class="mt-3"
    >
      <div class="modalChooseItemNumberContent d-flex">
        <!-- <div class="d-flex flex-wrap"> -->
        <div class="modalChooseItemNumberContent__content" :class="gameType === 'xsmn' ? 'layoutXSMN' : 'layoutXSMN'">
          <div class="vertical-line"></div>
          <div class="horizontal-line"></div>

          <span v-for="(i, index) in listAllNumber" :key="index">
            <span class="customNumberPeriodResult pointer">
              <div class="groupUIResult">
                <svg class="n2" v-if="checkPeriodResult(i, 2)">
                  <circle stroke-width="3" stroke-dashoffset="0"></circle>
                </svg>
                <svg class="n3" v-if="checkPeriodResult(i, 3)">
                  <circle stroke-width="3" stroke-dashoffset="0"></circle>
                </svg>
              </div>
              <NumberItem
                numberMix
                :isActive="activeIndex(i) >= 0"
                :number="i"
                class="pointer customNumber customPeriodResult pb-0"
                :class="{
                  n1: checkPeriodResult(i, 1)
                }"
              />
            </span>
          </span>
        </div>
      </div>
    </Tooltip>
    <p style="margin-top:30px;">Các số thần tài đã ban:</p>
    <div class="flex-number">
      <div class="numbers" v-for="(number, index) in listNumberActive" :key="index">
        {{ number }}
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="d-flex flex-column h-100 justify-content-center w-100">
        <div class="text-center boxBtnRemoveNumber">
          <div>
            <button type="submit" class="btn w-22 text-uppercase my-lg-2 btnCheck">
              Xác nhận
            </button>
          </div>
        </div>
      </div>
    </template>
    <div id="idReward"></div>
    <div class="overlayScreen" v-if="isShowOverlay && isPlayTrial"></div>
  </b-modal>
</template>
<script>
import { playTrialContent } from "@/common/global.js";
import Tooltip from "@/components/UI/Tooltip";
import NumberItem from "./Cart/NumberItem.vue";
export default {
  components: {
    Tooltip,
    NumberItem
    // ModalChooseFast
  },
  props: {
    modalChooseNumberItem: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "2 số đuôi"
    },
    titleModal: {
      type: String,
      default: "Thần tài ban số"
    },
    activeListNumber: {
      type: Array
    },
    activedMoney: {
      type: Number
    },
    listAllNumber: {
      type: Array
    },
    // checkActivedTicket: {
    //   type: Boolean,
    //   required: true
    // },
    gameId: {
      type: Number,
      required: true
    },
    gameType: String,
    productData: {
      type: Object
    },
    isShowChooseComboBox: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    activeListNumber: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.listNumberActive = newVal;
      }
    },
    activedMoney: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.actived = newVal;
      }
    },
    isListNumber: function(newVal) {
      if (newVal && this.checkIsShowStepTrial("choose_game_number_in_modal")) {
        this.runPlayTrial("choose_confirm_in_modal");
      }
    },
    modalChooseNumberItemData: function(newVal) {
      this.handleToggleModal(newVal);
    }
  },
  data() {
    return {
      modal: false,
      listNumberActive: this.activeListNumber,
      modalChooseFast: false,
      actived: this.activedMoney,
      arrPrizeKeno: [],
      productId: 0,
      price: 0,
      textNotice: '<p class="font-italic">Hãy chọn số lượng số phù hợp với cách chơi BAO</p>',
      tooltipChooseFast: playTrialContent[this.gameType],
      loadingRandomNumber: false
    };
  },
  computed: {
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    // listFavorite() {
    //   return this.$store.getters.getterListAllFavoriteDropdown;
    // },
    getterGameUrl() {
      return this.$store.getters.getterGameUrl;
    },
    countTotalNumber() {
      return this.listNumberActive.length;
    },
    isListNumber() {
      return (
        this.listNumberActive.length > 0 &&
        this.listNumberActive.length >= this.totalNumberRandom &&
        !this.loadingRandomNumber
      );
    },
    isListNumberTabHard() {
      return (
        this.listNumberActive.length > 0 &&
        this.listGuide.some((p) => p.number === this.listNumberActive.length) &&
        !this.loadingRandomNumber
      );
    },
    isListNumberTabHardPaymentIntegration() {
      if (!this.productData?.product_id) {
        return false;
      }
      return this.listNumberActive.length > 0 && this.listNumberActive.length === this.productData?.number;
    },
    modalChooseNumberItemData: {
      get() {
        return this.modalChooseNumberItem;
      },
      set(newVal) {
        this.$emit("setModalChooseNumberItem", newVal);
      }
    },
    isHeaderPlaytrial() {
      return this.$store.state.playTrial && this.$store.getters.getterLoggedIn;
    },
    isPlayTrial() {
      return this.$store.getters.getterPlayTrial;
    },
    isShowOverlay() {
      return this.$store.getters.getterShowOverlay;
    },
    isShowPeriod: {
      get() {
        return this.$store.getters.getterIsShowPeriodHint;
      },
      set(newVal) {
        this.$store.commit("setIsShowPeriodHint", newVal);
      }
    },
    threeLatestResult() {
      return this.$store.getters.getterThreeLatestResult[this.gameType] || [];
    },
    checkPeriodResult() {
      return (i, n) => {
        if (!this.isShowPeriod) return false;
        return this.threeLatestResult[n - 1]?.lottery_result?.includes(i);
      };
    }
  },
  methods: {
    updateModal(newObj) {
      this.modal = newObj;
    },
    activeIndex(value) {
      return this.listNumberActive[this.findIndexActive(this.listNumberActive, value)];
    },
    findIndexActive(array, value) {
      return array.findIndex((f) => f == value);
    },
    hideModal() {
      this.$emit("updateCheckShowPopup");
      this.arrPrizeKeno = [];
      this.listNumberActive = [];
      this.isShowPeriod = false;
      this.actived = this.activedMoney;
    },
    handlerConfirm(activedTab, tab) {
      this.runPlayTrial("choose_more_ticket_number");

      if (activedTab !== tab.tabBasic) {
        if (this.isListNumberTabHard) {
          this.$bus.$emit("updateActivePowerListNumber", {
            price: this.price,
            product_id: this.productId,
            listNumberActive: this.listNumberActive,
            title: this.title,
            listItem: this.$store.getters.getterListTicketPowerOverview
          });
          this.modalChooseNumberItemData = false;
        }
      } else {
        if (this.isListNumber) {
          this.$bus.$emit("updateActivePowerListNumber", {
            price: this.pricePowerBasic,
            product_id: this.productIdPowerBasic,
            listNumberActive: this.listNumberActive,
            title: this.title,
            listItem: this.$store.getters.getterListTicketPowerOverview
          });
          this.modalChooseNumberItemData = false;
        }
      }
    },
    handleNextStep() {
      if (this.activedGuide == this.objGuideTab.tabBasic ? !this.isListNumber : !this.isListNumberTabHard) {
        return;
      }
      this.runPlayTrial("choose_confirm_in_modal");
    }
  },
  beforeDestroy() {
    this.modalChooseNumberItemData = false;
    this.$emit("updateCheckShowPopup");
  }
};
</script>
<style lang="scss" scoped>
.flex-number {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin-top: 7px;
  gap: 4px;

  width: 336px;
  height: 30px;
}
.numbers {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #ffe9a0;
  border: 1px solid #cecece;
  margin: auto;
  padding: 2px;
  text-align: center;
  font-weight: bold;
}
.mb-5px {
  margin-bottom: 5px;
}
.btnRemove {
  background-color: #efefef;
  color: #0d234a;
  font-weight: 600;
  padding: 0.45rem 1.5rem;
  transition: 0.3s all;
  &:hover {
    background-color: #d9d9d9;
  }
}

.chooseNumberItem {
  .numberItem {
    padding: 0.375rem 0.475rem;
    border-radius: 3px;
    border: solid 1px #d9cbbb;
    background-color: #f7ede2;
    margin: 0 5px;
    text-align: center;
    transition: 0.3s all;
    color: #666666;
    font-weight: 600;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #e9cdaf;
    }
  }
}

.prizeWrapperContent {
  .card {
    background-color: #f7ede2;
    border: none;
    border-radius: 0.25rem;
    .card-body {
      padding: 0.35rem 1.25rem;
    }
    .prizeWrapperContentLine {
    }
    .row {
      border-top: 1px solid #ede8e3;
      padding-top: 0.6rem;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }
    }
  }
}
.prizeWrapperTitle {
  font-size: 0.875rem;
  color: #00509d;
}
.modalChooseItemNumberContent {
  height: auto;
  // overflow-x: auto;
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #ededed;
    border: 5px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.btnCheck {
  border-radius: 3px;
  // background-image: linear-gradient(to bottom, #e8a500, #d68c00);
  background-color: $primaryColor;
  color: #fff;
  padding: 0.45rem 1.5rem;
  // &:hover {
  //   color: #fff;
  //   background-color: #9a1834;
  // }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
  .btnCheckLucky {
    text-align: left;
  }

  .modalChooseItemNumber {
    .modalChooseItemNumberContent {
      height: auto;
    }
    .btnCheck {
      width: 157px !important;
    }
  }
}
.overlayScreen {
  z-index: 0;
}
</style>
