<template>
  <GameLayout
    :tutorialGame="tutorialGame"
    :gameTitle="'Vé số ' + this.nameGame"
    :gameStatus="gameStatus"
    :isLoading="isLoadingData"
  >
    <template slot="asideLeft">
      <div class="d-flex mb-2 flex-wrap flex-md-nowrap pt-5">
        <DropdownListTraditionalGame
          :data="periodList"
          :isOpen="isOpenPeriodList"
          @updateData="updatePeriodList"
          :itemActived="periodActived"
          @updateModal="updateModalPeriod"
          class="mb-xs-2 mr-md-2 periodList"
          v-if="isShowPeriodList"
          customBtnDropdown="customBtnDropdown"
        >
          <template slot="customItemActive">
            <div>
              Ngày mở thưởng: <strong>{{ getDay(periodActived.value) }}</strong
              >, ngày
              <strong>{{ formatDate(periodActived.value) }}</strong>
            </div>
          </template>
        </DropdownListTraditionalGame>
        <div class="position-relative searchBox">
          <img class="iconSearchTicket" src="@/assets/img/icon_search.svg" alt="icon search" />
          <b-form-input
            autocomplete="off"
            v-model="searchInput"
            trim
            id="searchInput"
            placeholder="Tìm số muốn mua"
            class="w-100 customInput"
            type="number"
            pattern="[0-9]*"
          ></b-form-input>
          <span @click="searchInput = ''" class="iconX">×</span>
        </div>
        <button
          v-if="isMobile"
          class="btn pointer luckyBtn"
          :class="{ mobile: isMobile }"
          @click="handleClickThanTaiBanSo()"
        >
          <img src="@/assets/img/thantai-banso.png" />
          <span>THẦN TÀI BAN SỐ </span>
        </button>
        <button v-if="isMobile && isHide" class="btn pointer view-all-ticket" @click="handleClickXemTatCaVe()">
          <span>XEM TẤT CẢ</span>
        </button>
        <div class="lucky-numbers" v-if="isHide && isMobile">
          <div><span>Các số thần tài đã ban:</span></div>
          <div class="flex-number">
            <div class="numbers" v-for="(number, index) in listNumber" :key="index">
              {{ number }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" class="d-flex flex-wrap gap-10">
        <button class="btn pointer luckyBtn" :class="{ hide: isHide }" @click="handleClickThanTaiBanSo()">
          <img src="@/assets/img/thantai-banso.png" />
          <span>THẦN TÀI BAN SỐ </span>
        </button>
        <button
          v-if="isHide"
          class="btn pointer view-all-ticket"
          :class="{ desktop: !isMobile }"
          @click="handleClickXemTatCaVe()"
        >
          <span>XEM TẤT CẢ</span>
        </button>
        <div class="lucky-numbers" v-if="isHide">
          <div><span>Các số thần tài đã ban:</span></div>
          <div class="flex-number">
            <div class="numbers" v-for="(number, index) in listNumber" :key="index">
              {{ number }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="periodActived && periodActived.productList.length && !isHide"
        class="groupProduct mb-2"
        :style="{
          gridTemplateColumns: `repeat(${
            periodActived.productList.length > 2 ? periodActived.productList.length : 2
          }, 1fr)`
        }"
      >
        <div
          class="groupProduct__item"
          v-for="(item, index) in periodActived.productList"
          :key="item.id"
          :class="{
            active: indexProductActive === index
          }"
          @click="indexProductActive = index"
        >
          <span>{{ item.product_name }}</span>
        </div>
      </div>

      <MaxGameBox title="Chọn vé mua" class="customMaxGameBox">
        <template slot="rightAction" v-if="!isHide">
          <div class="d-flex justify-content-end">
            <CommonCheckBox
              @updateCheckBox="isCheckShow = $event"
              :value="isCheckShow"
              label="Ẩn vé bán hết"
              class="text-left mb-0 mb-md-2"
            >
            </CommonCheckBox>
          </div>
        </template>
        <template v-if="isViewOnlyTicketLikeLuckyNumber && !isViewAllTicket" slot="bottomSlot">
          <div v-if="!isLoadingGetTicketList">
            <div v-for="item in periodActived.productList" :key="item.id" style="margin-top: 12px;">
              <div v-if="Object.keys(ticketGroupListFilter).length != 0">
                <span
                  class="product__name"
                  v-if="ticketGroupListFilter[`${item.product_id}`][0].group_tickets.length != 0"
                >
                  {{ item.product_name }}</span
                >
                <div
                  class="ticketGroup"
                  style="margin: 12px 10px 12px 0;"
                  v-for="ticketGroups in ticketGroupListFilter[`${item.product_id}`][0]"
                  :key="ticketGroups.id"
                >
                  <div
                    class="ticketGroup__item"
                    v-for="ticketGroup in ticketGroups"
                    :key="ticketGroup.id"
                    @click="handleClickItem(ticketGroup, true)"
                    :class="{
                      mobile: isMobile,
                      active: isInList(ticketGroup, true)
                    }"
                  >
                    <div class="ticketGroup__item__number font-weight-600">
                      <span class="color-gray">x</span>
                      <span v-if="ticketGroup.formatValue" v-html="ticketGroup.formatValue.slice(1)"> </span>
                      <span v-else>{{ ticketGroup.value.slice(1) }}</span>
                    </div>
                    <div class="text-right">
                      <div>110</div>
                      <div>CN</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="Object.keys(ticketRetailListFilter).length != 0">
                <div v-for="(ticketRetails, index) in ticketRetailListFilter[`${item.product_id}`][1]" :key="index">
                  <span
                    class="product__name"
                    v-if="
                      ticketGroupListFilter[`${item.product_id}`][0].group_tickets.length == 0 &&
                        ticketRetails.length != 0
                    "
                  >
                    {{ item.product_name }}</span
                  >
                  <div class="ticketRetail" style="margin-top:12px">
                    <div
                      class="ticketRetail__item"
                      v-for="ticketRetail in ticketRetails"
                      :key="ticketRetail.id"
                      @click="handleClickItem(ticketRetail)"
                      :class="{
                        mobile: isMobile,
                        disabled: !ticketRetail.remain_amount,
                        active: isInList(ticketRetail)
                      }"
                    >
                      <div
                        class="ticketRetail__item__number font-weight-600"
                        v-if="ticketRetail.formatValue"
                        v-html="ticketRetail.formatValue"
                      ></div>
                      <div v-else class="ticketRetail__item__number font-weight-600">
                        {{ ticketRetail.ticket_info }}
                      </div>
                      <div class="text-right">{{ ticketRetail.remain_amount }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="ticketGroup">
              <div v-for="(ticketGroup, index) in 20" :key="index">
                <content-placeholders>
                  <content-placeholders-img class="loadingTicket" />
                </content-placeholders>
              </div>
            </div>
          </div>
        </template>
        <template v-else slot="bottomSlot">
          <div v-if="!isLoadingGetTicketList">
            <div class="ticketGroup">
              <div
                class="ticketGroup__item"
                v-for="ticketGroup in ticketGroupListFilter"
                :key="ticketGroup.id"
                @click="handleClickItem(ticketGroup, true)"
                :class="{
                  mobile: isMobile,
                  active: isInList(ticketGroup, true)
                }"
              >
                <div class="ticketGroup__item__number font-weight-600">
                  <span class="color-gray">x</span>
                  <span v-if="ticketGroup.formatValue" v-html="ticketGroup.formatValue.slice(1)"> </span>
                  <span v-else>{{ ticketGroup.value.slice(1) }}</span>
                </div>
                <div class="text-right">
                  <div>110</div>
                  <div>CN</div>
                </div>
              </div>
            </div>
            <hr v-if="ticketGroupListFilter.length" />
            <div class="ticketRetail">
              <div
                class="ticketRetail__item"
                v-for="ticketRetail in ticketRetailListFilter"
                :key="ticketRetail.id"
                @click="handleClickItem(ticketRetail)"
                :class="{
                  mobile: isMobile,
                  disabled: !ticketRetail.remain_amount,
                  active: isInList(ticketRetail)
                }"
              >
                <div
                  class="ticketRetail__item__number font-weight-600"
                  v-if="ticketRetail.formatValue"
                  v-html="ticketRetail.formatValue"
                ></div>
                <div v-else class="ticketRetail__item__number font-weight-600">
                  {{ ticketRetail.ticket_info }}
                </div>
                <div class="text-right">{{ ticketRetail.remain_amount }}</div>
              </div>
            </div>

            <div v-if="!ticketGroupListFilter.length && !ticketRetailListFilter.length" class="text-center mt-5">
              <img :draggable="false" src="@/assets/img/image_no_ticket.png" alt="no ticket" />
              <div>Rất tiếc, không có vé.</div>
            </div>
          </div>
          <div v-else>
            <div class="ticketGroup">
              <div v-for="(ticketGroup, index) in 20" :key="index">
                <content-placeholders>
                  <content-placeholders-img class="loadingTicket" />
                </content-placeholders>
              </div>
            </div>
          </div>
        </template>
      </MaxGameBox>

      <!-- MODAL -->
      <ModalKhongCoVeThanTaiBanSo
        v-show="true"
        @updateOpen="isOpenPopupKhongCoVeThanTaiBanSo = $event"
        dialogClass="popupKhongCoVeThanTaiBanSo"
        :isOpen="isOpenPopupKhongCoVeThanTaiBanSo"
        :isHiddenFooter="true"
      >
        <div class="no-thantai-container">
          <div class="image"><img src="@/assets/img/thantaibanso-no.png" /></div>
          <div class="sorry"><strong>Rất Tiếc</strong></div>
          <div class="sorry-content">
            <span
              >Đã hết vé thần tài ban vào {{ getDay(periodActived.value) }} ngày
              {{ formatDate(periodActived.value) }}</span
            >
          </div>
          <div class="btn-back-to-viewAllTickets">
            <button @click="handleClickComeBack()">
              <Strong>QUAY LẠI</Strong>
            </button>
          </div>
        </div>
      </ModalKhongCoVeThanTaiBanSo>
      <ModalThanTaiBanSo
        v-show="true"
        @updateOpen="isOpenPopupThanTaiBanSo = $event"
        dialogClass="popupThanTaiBanSo"
        :class="{ mobile: isMobile }"
        :isOpen="isOpenPopupThanTaiBanSo"
        title="Thần tài ban số"
        :isHiddenFooter="true"
        @handleHide="handleHideThanTaiBanSo"
      >
        <div class="thantai-container">
          <div class="date-time" :class="{ desktop: !isMobile }">
            <span
              >Ngày mở thưởng: <strong>{{ getDay(periodActived.value) }}</strong
              >, ngày <strong>{{ formatDate(periodActived.value) }}</strong></span
            >
          </div>
          <img src="@/assets/img/thantai-banso.png" class="image" />
          <div class="listNumber__flexBox" :class="{ desktop: !isMobile }">
            <div class="row" v-for="(row, index) in rows" :key="index">
              <div class="item" v-for="(number, index) in row" :key="index">
                <strong>{{ number }}</strong>
              </div>
            </div>
          </div>
          <button class="btn-listNumber" :class="{ desktop: !isMobile }" @click="handleClickXemVeThanTaiBan()">
            <Strong>XEM VÉ THẦN TÀI BAN</Strong>
          </button>
        </div>
      </ModalThanTaiBanSo>
      <ModalChooseNumberItem
        @setModalChooseNumberItem="setModalChooseNumberItem"
        @updateCheckShowPopup="updateCheckShowPopup"
        :modalChooseNumberItem="modalChooseNumberItem"
        :activeListNumber="activeListNumber"
        :listAllNumber="listAllNumber"
        :gameId="gameId"
      />
      <CommonModal
        v-show="true"
        @updateOpen="isOpenPopupChooseTicket = $event"
        dialogClass="popupChooseticket"
        :isOpen="isOpenPopupChooseTicket"
        title="Chọn vé"
        :isHiddenFooter="true"
        @handleHide="isLoadingImg = true"
      >
        <div v-if="chooseItem">
          <div class="chooseItemCtn" v-if="chooseItem.ticket_info">
            <div class="position-relative">
              <img
                draggable="false"
                :alt="`Hình vé ${chooseItem.ticket_info}`"
                :src="chooseItem.image_url"
                width="100%"
                class="mb-2"
                @load="handleLoadImg"
              />
              <div class="loadingImgCtn">
                <content-placeholders>
                  <content-placeholders-img class="loadingImg" />
                </content-placeholders>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <span>{{ productChooseData.product_name }}</span>
              <span>
                Số vé còn lại
              </span>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <span class="chooseItemCtn__info">{{ chooseItem.ticket_info }}</span>
              <span class="chooseItemCtn__total"> {{ chooseItem.remain_amount }} vé </span>
            </div>
          </div>
          <div class="chooseItemCtn" v-else>
            <img
              draggable="false"
              :alt="`Hình vé ${chooseItem.value.replace('*', 'x')}`"
              :src="chooseItem.tickets[0].image_url"
              width="100%"
              class="mb-2"
            />
            <div class="d-flex justify-content-between align-items-end">
              <span>{{ productChooseData.product_name }}</span>

              <span>
                Vé cặp nguyên
              </span>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <span class="chooseItemCtn__info"
                ><span class="color-gray">x</span>{{ chooseItem.value.slice(1) }}
                <img
                  @click="openGroupTicketDetail"
                  class="pointer"
                  src="@/assets/img/info-circle.png"
                  alt="icon search"
                />
              </span>
              <span class="chooseItemCtn__total"> {{ chooseItem.tickets[0].stock_amount * 10 }} vé </span>
            </div>
          </div>
          <template name="slotFooter">
            <div class="d-flex mt-3">
              <div class="w-50">
                <ButtonQuantity v-model="quantity" :max="chooseItem.remain_amount" :isBlock="!!chooseItem.value" />
              </div>
              <button @click="handleUpdateChooseTicket" class="btn btnChose">
                Chọn mua
              </button>
            </div>
          </template>
        </div>
      </CommonModal>

      <CommonModal
        @updateOpen="isOpenGroupTicketDetail = $event"
        dialogClass="popupGroupTicketDetail"
        :isOpen="isOpenGroupTicketDetail"
        :title="`Vé cặp nguyên ${chooseGroupItemName}`"
        :isHiddenFooter="true"
        @handleHide="handleHideGroupTicketDetail"
      >
        <div v-if="chooseItem && chooseItem.tickets && chooseItem.tickets.length">
          <div class="groupDetail">
            <div v-for="ticket in chooseItem.tickets" :key="ticket.id">
              <div class="d-flex align-items-center justify-content-between groupDetail__item text-line-1">
                <span class="font-18 font-weight-600">{{ ticket.ticket_info }}</span>
                <span class="">{{ ticket.stock_amount }} vé</span>
              </div>
            </div>
          </div>
        </div>
      </CommonModal>

      <CommonModal
        @updateOpen="isOpenNotiUpdateTicket = $event"
        dialogClass="popupUpdateTicket"
        :isOpen="isOpenNotiUpdateTicket"
        title="Thông báo"
        :isHiddenFooter="true"
      >
        <div class="text-center pb-4 pt-3">
          <img draggable="false" alt="cap nhat ve" src="@/assets/img/ticket_update.png" />
          <div class="my-3">
            Vé bạn chọn đã cập nhật
          </div>
        </div>
      </CommonModal>

      <CommonModal
        @updateOpen="isOpenTicketChoose = $event"
        dialogClass="popupTicketChoose"
        :isOpen="isOpenTicketChoose"
        title="Vé đã chọn"
        :isConfirmBtn="false"
        cancleTextBtn="Đóng"
        :isCustomUI="true"
        :isFullWidthCancelButton="true"
      >
        <div class="text-left">
          <ListTicketChoose
            :numberTicketPayment="numberTicketPayment"
            :chooseItemListFormat="chooseItemListFormat"
            @handleClickItem="handleClickItem"
            @handleRemove="handleRemove"
          />
          <hr />
          <GroupGameInfoPayment
            class=""
            :totalPrice="totalPriceItem"
            :fee="calcTransactionFee.transactionFee"
            :resultPrice="calcTransactionFee.resultPrice"
          />
        </div>
      </CommonModal>
      <!-- END MODAL -->
    </template>
    <template slot="asideRight">
      <AddToCardBox
        :totalPrice="totalPriceItem"
        :transactionFee="calcTransactionFee.transactionFee"
        :resultPrice="calcTransactionFee.resultPrice"
        :isBuyNow="isBuyNow"
        @buyNow="handleBuyNow"
        :gameId="gameId"
        :isDisabled="numberTicketPayment < 1"
        @quickBuy="handleQuickBuy"
        :hiddenQuickBuy="hiddenQuickBuy || true"
        :isDisabledQuickBuy="isDisabledQuickBuy"
        :isHideAddtoCardBtn="true"
      >
        <template slot="slotTop">
          <div class="text-left d-none d-lg-block">
            <p class="font-18 mb-3 pb-1">Vé đã chọn</p>
            <ListTicketChoose
              :numberTicketPayment="numberTicketPayment"
              :chooseItemListFormat="chooseItemListFormat"
              @handleClickItem="handleClickItem"
              @handleRemove="handleRemove"
            />
            <hr />
          </div>
          <div class=" d-block d-lg-none color-blue mb-1" @click="isOpenTicketChoose = true">
            Xem <strong>{{ totalTicket }}</strong> vé đã chọn
          </div>
        </template>
      </AddToCardBox>
    </template>
    <template slot="maintaince">
      <MaintainceGame :gameName="nameGame" :customText="true" />
    </template>
  </GameLayout>
</template>

<script>
import GameLayout from "@/components/Game/GameLayout";
import MaxGameBox from "@/components/MaxGame/MaxGameBox";

import AddToCardBox from "@/components/Game/AddToCardBox";

import GameAppService from "@/api/game";
import { Noti } from "@/main";

import MaintainceGame from "./MaintainceGame";

import { isMobile } from "mobile-device-detect";
import CommonCheckBox from "../../../components/CommonCheckBox.vue";
import DropdownListTraditionalGame from "../../../components/Game/DropdownListTraditionalGame.vue";
import CommonModal from "../../../components/CommonModal.vue";
import ModalThanTaiBanSo from "../../../components/ModalThanTaiBanSo.vue";
import ModalKhongCoVeThanTaiBanSo from "../../../components/ModalKhongCoVeThanTaiBanSo.vue";
import ModalChooseNumberItem from "../../../components/ModalChooseNumberItem.vue";
import ButtonQuantity from "../../../components/ButtonQuantity.vue";
import ListTicketChoose from "../../../components/TraditionalGame/ListTicketChoose";
import GroupGameInfoPayment from "../../../components/Game/GroupGameInfoPayment.vue";
const PREFIX_GROUP_ID = "group_";

export default {
  props: {
    gameId: Number,
    nameGame: String,
    tutorialGame: String,
    shipData: Object,
    paymentData: Object,
    paymentDataQuickBuy: Object,
    paymentDataIntegration: Object,
    paymentDataPartner: Object,
    gameHandler: String,
    updateNearestPeriodChoose: Function,
    nearestPeriodChoose: Object,
    hiddenQuickBuy: Boolean,
    isDisabledQuickBuy: Boolean
  },
  components: {
    MaintainceGame,
    GameLayout,
    MaxGameBox,
    AddToCardBox,
    CommonCheckBox,
    DropdownListTraditionalGame,
    CommonModal,
    ButtonQuantity,
    ListTicketChoose,
    GroupGameInfoPayment,
    ModalThanTaiBanSo,
    ModalKhongCoVeThanTaiBanSo,
    ModalChooseNumberItem
  },
  data() {
    return {
      isLoadingData: true,
      periodList: [],
      isOpenPeriodList: false,
      periodActived: null,
      ticketList: [],
      productOfGame: null,
      gameData: null,
      isBuyNowCheckLogin: false,
      isMobile,
      searchInput: "",
      isCheckShow: true,
      indexProductActive: 0,
      ticketRetailList: [],
      ticketGroupList: [],
      allTicketList: {},
      isOpenPopupChooseTicket: false,
      isOpenPopupThanTaiBanSo: false,
      isOpenPopupKhongCoVeThanTaiBanSo: false,
      modalChooseNumberItem: false,
      listNumber: [],
      listAllNumber: [],
      activeListNumber: [],
      row: 2,
      isViewAllTicket: false,
      isViewOnlyTicketLikeLuckyNumber: false,
      isLoadingGetListLuckyNumber: false,
      isHide: false,
      chooseItem: null,
      quantity: 1,
      chooseItemList: [],
      isLoadingGetTicketList: true,
      isOpenGroupTicketDetail: false,
      isOpenNotiUpdateTicket: false,
      isOpenTicketChoose: false,
      pusherChannels: [],
      isLoadingImg: true
    };
  },
  created() {
    this.getListLuckyNumber();
    this.getListAllNumber();
  },
  computed: {
    rows() {
      let rows = [];
      let mid = Math.ceil(this.listNumber.length / this.row);
      for (let i = 0; i < this.row; i++) {
        rows.push(this.listNumber.slice(i * mid, i * mid + mid));
      }
      return rows;
    },

    getIndexProduct() {
      return this.$route.query.indexProduct;
    },
    gameStatus() {
      return this.$store.getters.getterGameStatus[this.gameId];
    },
    listGameHandlerById() {
      return this.convertArrayToObject(Object.values(this.$store.getters.getterListGameHandler), "id");
    },
    totalPriceItem() {
      if (!this.gameData) {
        return 0;
      }
      return this.chooseItemList.reduce((value, item) => {
        const productHandler = item.productHandler || this.productChooseData.product_handler;
        if (item?.tickets?.length) {
          return (value += (this.productOfGame[productHandler].price / +this.gameData.price) * 10000 * item.quantity);
        }

        return (value += (this.productOfGame[productHandler].price / +this.gameData.price) * 10000 * item.quantity);
      }, 0);
    },
    isBuyNow() {
      return true;
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    gameType() {
      return this.listGameHandlerById[this.gameId].handler;
    },
    isShowPeriodList() {
      return this.periodList?.length;
    },
    getCheckLoginAfterBuyGame() {
      return this.$store.getters.getterCheckLoginAfterBuyGame;
    },
    gameUrl() {
      return this.convertArrayToObject(this.$store.getters.getterGameUrl, "id");
    },
    gameName() {
      const name = this.gameUrl[this.gameId].slug;
      if (!name) return "";
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    getTraditionalTimer() {
      if (!this.gameType) return 0;
      return this.$store.state.CD.xsmnHomeTimer;
    },
    calcTransactionFee() {
      // const { paymentRate, paymentFee } = this.paymentData;

      const { paymentRate, paymentFee } = this.isPartnerMode
        ? this.paymentDataPartner
        : this.isPaymentIntegration
        ? this.paymentDataIntegration
        : this.paymentData;

      const { shipRate, shipFee } = this.shipData;
      return this.getTransactionFee(this.totalPriceItem, shipRate, shipFee, paymentRate, paymentFee);
    },
    numberTicketPayment() {
      return this.chooseItemList.filter((item) => item.quantity).length;
    },
    isHandleTrial() {
      return this.$store.state.playTrial && this.loggedIn;
    },
    ticketRetailListFilter() {
      if (this.isViewOnlyTicketLikeLuckyNumber == false) {
        if (!this.ticketRetailList.length) return [];
        if (!this.searchInput.length) {
          return this.ticketRetailList.filter((item) => !(this.isCheckShow && !item.remain_amount));
        }
        const cloneObj = JSON.parse(JSON.stringify(this.ticketRetailList));
        return cloneObj
          .filter((item) => {
            if (this.isCheckShow && !item.remain_amount) {
              return false;
            }
            return item.ticket_info.includes(this.searchInput);
          })
          .map((item) => {
            let lIndex = item.ticket_info.lastIndexOf(this.searchInput);

            item.formatValue =
              item.ticket_info.slice(0, lIndex) +
              `<span class="color-red-1 font-weight-600">${this.searchInput}</span>` +
              item.ticket_info.slice(lIndex + this.searchInput.length);

            return item;
          });
      } else {
        if (Object.keys(this.allTicketList).length == 0) return [];
        if (!this.searchInput.length) {
          for (let i = 0; i < this.periodActived.productList.length; i++) {
            this.allTicketList[this.periodActived.productList[i].product_id][1].retail_tickets.filter(
              (item) => !(this.isCheckShow && !item.remain_amount)
            );
          }
          return this.allTicketList;
        }
        const cloneObj = JSON.parse(JSON.stringify(this.allTicketList));
        for (const [key, value] of Object.entries(cloneObj)) {
          if (value[1].retail_tickets.length != 0 && key != 0)
            value[1].retail_tickets.reduceRight((acc, item, index, object) => {
              if (typeof item.ticket_info != "string") item.ticket_info = item.ticket_info.toString();
              if (this.isCheckShow && !item.remain_amount) {
                return false;
              }
              if (item.ticket_info.includes(this.searchInput)) {
                return true;
              }
              object.splice(index, 1);
            }, []);
          value[1].retail_tickets.map((item) => {
            if (typeof item.ticket_info != "string") item.ticket_info = item.ticket_info.toString();
            let lIndex = item.ticket_info.lastIndexOf(this.searchInput);

            item.formatValue =
              item.ticket_info.slice(0, lIndex) +
              `<span class="color-red-1 font-weight-600">${this.searchInput}</span>` +
              item.ticket_info.slice(lIndex + this.searchInput.length);

            return item;
          });
        }

        return cloneObj;
      }
    },
    ticketGroupListFilter() {
      if (!this.isViewOnlyTicketLikeLuckyNumber) {
        if (!this.ticketGroupList.length) return [];
        if (!this.searchInput.length) return this.ticketGroupList;
        const cloneObj = JSON.parse(JSON.stringify(this.ticketGroupList));
        return cloneObj
          .filter((item) => {
            return item.value.includes(this.searchInput);
          })
          .map((item) => {
            item.value = item.value.replace(
              this.searchInput,
              `<span class="color-red-1 font-weight-600">${this.searchInput}</span>`
            );

            let lIndex = item.value.lastIndexOf(this.searchInput);

            item.formatValue =
              item.value.slice(0, lIndex) +
              `<span class="color-red-1 font-weight-600">${this.searchInput}</span>` +
              item.value.slice(lIndex + this.searchInput.length);

            return item;
          });
      } else {
        if (!Object.keys(this.allTicketList).length) return [];
        if (!this.searchInput.length) {
          return this.allTicketList;
        }
        const cloneObj = JSON.parse(JSON.stringify(this.allTicketList));
        for (const [key, value] of Object.entries(cloneObj)) {
          if (value[0].group_tickets.length != 0 && key != 0) {
            value[0].group_tickets.reduceRight((acc, item, index, object) => {
              if (typeof item.value != "string") item.value = item.value.toString();
              if (item.value.includes(this.searchInput)) {
                return true;
              } else {
                object.splice(index, 1);
              }
            }, []);
            value[0].group_tickets.map((item) => {
              if (typeof item.value != "string") item.value = item.value.toString();
              let lIndex = item.value.lastIndexOf(this.searchInput);
              item.formatValue =
                item.value.slice(0, lIndex) +
                `<span class="color-red-1 font-weight-600">${this.searchInput}</span>` +
                item.value.slice(lIndex + this.searchInput.length);
              return item;
            });
          }
        }
        return cloneObj;
      }
    },
    productChooseData() {
      if (this.isViewOnlyTicketLikeLuckyNumber == true) {
        const productList = this.periodActived?.productList || [];
        for (let i = 0; i < productList.length; i++) {
          if (productList[i].product_id == this.chooseItem.product_id) {
            return productList[i];
          }
        }
        return this.periodActived?.productList?.[this.indexProductActive] || {};
      } else {
        return this.periodActived?.productList?.[this.indexProductActive] || {};
      }
    },
    chooseItemListFormat() {
      if (!this.chooseItemList.length) return {};
      const result = {};
      this.chooseItemList.forEach((item) => {
        if (result[item.productId]) {
          result[item.productId].ticketList.push({
            ...item
          });
        } else {
          result[item.productId] = {
            productName: item.productName,
            productId: item.productId,
            productHandler: item.productHandler,
            ticketList: [
              {
                ...item
              }
            ]
          };
        }
      });
      return result;
    },
    isInList() {
      return function(data, isGroup) {
        if (isGroup) {
          return this.chooseItemList.some((chooseItem) => chooseItem.id === PREFIX_GROUP_ID + data.tickets[0].id);
        }
        return this.chooseItemList.some((chooseItem) => chooseItem.id === data.id);
      };
    },
    totalTicket() {
      return this.chooseItemList.reduce((value, item) => {
        return (value += item.quantity);
      }, 0);
    },
    chooseGroupItemName() {
      if (!this.chooseItem?.value) return "";

      return this.chooseItem.value.replace("*", "x");
    },
    listChannel() {
      let resultChannel = new Set();
      this.periodActived?.productList?.forEach((item) => {
        let channelName = `LOTTERY/${item.id}/${item.product_id}`;
        resultChannel.add(channelName);
      });

      return [...resultChannel];
    },
    currentTimestamp() {
      return this.$store.state.CD.currentTimestamp;
    }
  },
  methods: {
    updateCheckShowPopup() {
      this.$emit("updateCheckShowPopup");
    },
    updatePeriodList(newObj) {
      if (!newObj?.productList?.[this.indexProductActive]) {
        this.indexProductActive = 0;
      }
      this.periodActived = newObj;
    },
    updateModalPeriod(value) {
      this.isOpenPeriodList = value;
    },

    async getListPeriod() {
      try {
        const { data, status } = await GameAppService.getPeriodsByGameId(this.gameId).then((res) => res.data);
        if (status && data?.length) {
          this.$set(
            this,
            "periodList",
            data
              .filter((item) => {
                const timestamp = new CustomDate(item.lottery_time).getTime();
                let now = CustomDate.now();
                return now <= timestamp - (this.gameData.metadata.count_down || 0) * 1000 - 1000;
              })
              .map((item, index) => ({
                key: index,
                value: item.lottery_time,
                isActive: true,
                handler: "",
                productList: item.periods,
                productId: item.product_id,
                period_no: item.period_no
              }))
          );
          if (this.$route.query.period_no) {
            this.$set(this, "periodActived", {
              ...this.periodList.find((i) => i.value === this.$route.query.period_no)
            });
            delete this.$route.query.period_no;
          } else if (this.periodList[0]) {
            this.$set(this, "periodActived", { ...this.periodList[0] });
          }
        } else {
          throw new Error("Can't load period data !");
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async getListProductByGame() {
      this.isLoadingData = true;
      let partner = this.getterPartnerData?.handler;
      try {
        const { data, status } = await GameAppService.getPrizeByGameId(this.gameId, partner).then((res) => res.data);
        if (status) {
          const { metadata, price, products } = data;
          this.productOfGame = {
            ...products
          };
          this.gameData = {
            price: price,
            metadata: JSON.parse(metadata)
          };
        } else {
          throw new Error("Can't load product of game !");
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoadingData = false;
      }
    },

    async getTicketListFromHomePage() {
      try {
        if (this.getIndexProduct) {
          this.indexProductActive = Number(this.getIndexProduct);
          this.isLoadingGetTicketList = true;
          const { productList } = this.periodActived;
          const { data } = await GameAppService.getTraditionalTicket({
            game_id: this.gameId,
            product_id: productList?.[this.indexProductActive]?.product_id,
            period_id: productList?.[this.indexProductActive]?.id,
            product_handler: productList?.[this.indexProductActive]?.product_handler
          }).then((res) => res.data);
          this.$set(this, "ticketRetailList", data?.retail_tickets || []);
          this.$set(this, "ticketGroupList", data?.group_tickets || []);
        }
        this.isLoadingGetTicketList = true;
        const { productList } = this.periodActived;
        const { data } = await GameAppService.getTraditionalTicket({
          game_id: this.gameId,
          product_id: productList?.[this.indexProductActive]?.product_id,
          period_id: productList?.[this.indexProductActive]?.id,
          product_handler: productList?.[this.indexProductActive]?.product_handler
        }).then((res) => res.data);
        this.$set(this, "ticketRetailList", data?.retail_tickets || []);
        this.$set(this, "ticketGroupList", data?.group_tickets || []);
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoadingGetTicketList = false;
      }
    },
    checkNullTicketList(object) {
      let isNull = 0;
      for (const [key, value] of Object.entries(object)) {
        if (value[0].group_tickets.length != 0 && key > 0) isNull += 1;
        if (value[1].retail_tickets.length != 0 && key > 0) isNull += 1;
      }
      return isNull == 0;
    },
    async getTicketList() {
      try {
        this.isLoadingGetTicketList = true;
        const { productList } = this.periodActived;
        if (this.isViewOnlyTicketLikeLuckyNumber == true) {
          const response = new Object();
          for (let i = 0; i < productList.length; i++) {
            let resp = [{ group_tickets: [] }, { retail_tickets: [] }];
            let { data } = await GameAppService.getTraditionalTicket({
              game_id: this.gameId,
              product_id: productList[i]?.product_id,
              period_id: productList[i]?.id,
              product_handler: productList[i]?.product_handler
            }).then((res) => res.data);
            data.retail_tickets.reduceRight((acc, item, index, object) => {
              if (data.retail_tickets.length == 0) return true;
              if (typeof item.ticket_info == "string") {
                if (
                  Object.values(this.listNumber).includes(item.ticket_info.slice(-2)) &&
                  item.status_sale == 1 &&
                  item.remain_amount > 0
                ) {
                  return true;
                }
              } else {
                if (
                  Object.values(this.listNumber).includes(item.ticket_info.toString().slice(-2)) &&
                  item.status_sale == 1 &&
                  item.remain_amount > 0
                ) {
                  return true;
                }
              }
              object.splice(index, 1);
            }, []);
            data.group_tickets.reduceRight((acc, item, index, object) => {
              if (data.group_tickets.length == 0) return true;
              if (typeof item.value == "string") {
                if (Object.values(this.listNumber).includes(item.value.slice(-2)) && item.status_sale == 1) {
                  return true;
                }
              } else {
                if (Object.values(this.listNumber).includes(item.value.toString().slice(-2)) && item.status_sale == 1) {
                  return true;
                }
              }
              object.splice(index, 1);
            }, []);
            resp[0].group_tickets.push(...data.group_tickets);
            resp[1].retail_tickets.push(...data.retail_tickets);
            response[productList[i].product_id] = resp;
          }
          if (!this.checkNullTicketList(response)) {
            this.$set(this, "allTicketList", response || response);
          } else {
            this.$set(this, "allTicketList", [] || []);
          }
        } else {
          const { data } = await GameAppService.getTraditionalTicket({
            game_id: this.gameId,
            product_id: productList?.[this.indexProductActive]?.product_id,
            period_id: productList?.[this.indexProductActive]?.id,
            product_handler: productList?.[this.indexProductActive]?.product_handler
          }).then((res) => res.data);
          this.$set(this, "ticketRetailList", data?.retail_tickets || []);
          this.$set(this, "ticketGroupList", data?.group_tickets || []);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoadingGetTicketList = false;
        this.isViewOnlyTicketLikeLuckyNumber = false;
      }
    },
    async getListLuckyNumber() {
      try {
        this.isLoadingGetListLuckyNumber = true;
        const { data } = await GameAppService.getNumberGen().then((res) => res);
        this.$set(this, "listNumber", data?.data || []);
        this.$set(this, "activeListNumber", data?.data || []);
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoadingGetListLuckyNumber = false;
      }
    },
    getListAllNumber() {
      try {
        let arr = [];
        for (let i = 1; i < 100; i++) {
          arr.push(i);
        }
        arr.push(0);
        this.$set(this, "listAllNumber", arr || []);
      } catch (error) {
        console.error(error.message);
      }
    },
    checkLogin(isLogin) {
      this.$store.commit("setCheckLoginAfterBuyGame", false);
      if (!isLogin) {
        this.$store.commit("setCheckLoginBuyGame", true);
        this.$store.commit("setRedirectAfterLogin", null);
        this.$store.commit("setModalLogin", true);
        this.$store.commit("setIsBuyGameOauth2", true);
      }
      return isLogin;
    },

    async handleBuyNow() {
      if (!this.checkLogin(this.loggedIn)) {
        this.isBuyNowCheckLogin = true;
        return;
      }

      this.emitDataToConfirmPayment();
    },
    // new

    emitDataToConfirmPayment() {
      const { transactionFee, resultPrice } = this.calcTransactionFeeOrder();
      let infoProduct = {};

      for (const ticket of this.chooseItemList) {
        if (ticket.quantity) {
          const productPrice = (this.productOfGame[ticket.productHandler].price / this.gameData.price) * 10000;
          const product_id = ticket.value
            ? `${ticket.productId}_${ticket.lotteryTime}_${ticket.value}`
            : `${ticket.productId}_${ticket.lotteryTime}`;
          if (infoProduct[product_id]) {
            infoProduct[product_id].totalPrice += productPrice * ticket.quantity;
            infoProduct[product_id].numberTicketPayment += ticket.quantity;
          } else {
            infoProduct[product_id] = {
              totalPrice: productPrice * ticket.quantity,
              numberTicketPayment: ticket.quantity,
              product_id,
              productLabel: ticket.productName,
              nameGame: this.nameGame,
              lotteryTime: ticket.lotteryTime,
              isGroup: !!ticket.value,
              isTraditional: true
            };
          }
        }
      }

      this.$emit("openModalConfirmPayment", {
        transactionFee,
        resultPrice,
        listProduct: Object.values(infoProduct)
      });
    },
    handlerBuyNow() {
      const ticketData = this.chooseItemList.map((item) => {
        const productPrice = (this.productOfGame[item.productHandler].price / this.gameData.price) * 10000;
        return {
          period: [item.periodNo],
          game_id: this.gameId,
          ticket: [
            {
              ticket_ids: item.ticket_info ? [item.id] : item.tickets.map((ticketData) => ticketData.id),
              product_id: item.productId,
              quantity: item.ticket_info ? item.quantity : item.quantity / 10,
              price: productPrice * item.quantity
            }
          ]
        };
        // return {
        //   ...item,
        //   product_id: this.productOfGame[productHandler.handler].product_id,
        //   price: (this.productOfGame[productHandler.handler].price / this.gameData.price) * item.price,
        //   ticket_info
        // };
      });

      // const additionalData = {};

      // const refSelectIdol = this.isMobile ? this.$refs.selectIdolMobile : this.$refs.selectIdol;

      // if (refSelectIdol.itemActived?.invite_code && refSelectIdol.isGiftIdol) {
      //   additionalData.gift_code = refSelectIdol.itemActived.invite_code;
      //   additionalData.gift_message = refSelectIdol.messageForIdol;
      // }

      this.$emit(
        "handlerSendDataBuyNowTraditionGame",
        ticketData,
        () => {
          this.clearTicketAfterPayment();
        }
        // additionalData
      );
    },

    handleQuickBuy() {
      this.emitDataToConfirmPayment(true);
    },
    clearTicketAfterPayment() {
      this.$set(this, "chooseItemList", []);
    },
    calcTransactionFeeOrder(isQuickBuy = false) {
      const { paymentRate, paymentFee } = this.isPartnerMode
        ? this.paymentDataPartner
        : this.isPaymentIntegration
        ? this.paymentDataIntegration
        : isQuickBuy
        ? this.paymentDataQuickBuy
        : this.paymentData;

      const { shipRate, shipFee } = this.shipData;
      return this.getTransactionFee(this.totalPriceItem, shipRate, shipFee, paymentRate, paymentFee);
    },
    async getInitData() {
      await this.getListProductByGame();
      await this.getListPeriod();
      // await Promise.all([this.getListPeriod(), this.getListProductByGame()]);
      this.getTicketListFromHomePage();
    },
    handleClickXemTatCaVe() {
      this.isViewOnlyTicketLikeLuckyNumber = false;
      this.isViewAllTicket = true;
      this.isHide = false;
    },
    handleClickXemVeThanTaiBan() {
      if (Object.keys(this.allTicketList).length == 0) {
        this.isViewAllTicket = false;
        this.isOpenPopupThanTaiBanSo = false;
        this.isOpenPopupKhongCoVeThanTaiBanSo = true;
      } else {
        this.isViewAllTicket = false;
        this.isViewOnlyTicketLikeLuckyNumber = true;
        this.isOpenPopupThanTaiBanSo = false;
        this.isOpenPopupKhongCoVeThanTaiBanSo = false;
        this.isHide = true;
      }
    },
    handleClickComeBack() {
      this.isViewAllTicket = true;
      this.isViewOnlyTicketLikeLuckyNumber = false;
      this.isOpenPopupKhongCoVeThanTaiBanSo = false;
      this.isHide = false;
    },
    handleClickThanTaiBanSo() {
      this.isOpenPopupThanTaiBanSo = true;
      this.getListLuckyNumber();
      this.isViewOnlyTicketLikeLuckyNumber = true;
      this.getTicketList();
    },
    handleClickChooseNumberItem() {
      this.modalChooseNumberItem = true;
    },
    setModalChooseNumberItem(newVal) {
      this.modalChooseNumberItem = newVal;
    },
    handleClickItem(item, isGroup) {
      if (!item.remain_amount && !isGroup) {
        return;
      }

      const foundIndex = this.chooseItemList.findIndex((itemList) => {
        if (isGroup) {
          return itemList.id === PREFIX_GROUP_ID + item.tickets[0].id;
        }
        return itemList.id === item.id;
      });
      if (isGroup) {
        this.quantity = item.tickets[0].stock_amount * 10;
      } else {
        this.quantity = foundIndex < 0 ? 1 : this.chooseItemList[foundIndex].quantity;
      }
      this.$set(this, "chooseItem", item);
      this.isOpenPopupChooseTicket = true;
    },
    handleUpdateChooseTicket() {
      const foundIndex = this.chooseItemList.findIndex((item) => {
        if (this.chooseItem.value) {
          return item.id === PREFIX_GROUP_ID + this.chooseItem.tickets[0].id;
        }
        return item.id === this.chooseItem.id;
      });
      const newData = {
        ...this.chooseItem,
        productName: this.chooseItem.productName || this.productChooseData.product_name,
        productHandler: this.chooseItem.productHandler || this.productChooseData.product_handler,
        productId: this.chooseItem.productId || this.productChooseData.product_id,
        lotteryTime: this.chooseItem.lotteryTime || this.periodActived.value,
        periodId: this.chooseItem.periodId || this.productChooseData.id,
        periodNo: this.chooseItem.periodNo || this.productChooseData.period_no,
        quantity: this.quantity
      };

      if (foundIndex >= 0) {
        this.$set(this.chooseItemList, foundIndex, newData);
      } else {
        // Vé nguyên
        if (this.chooseItem.value) {
          newData.id = PREFIX_GROUP_ID + this.chooseItem.tickets[0].id;
        }

        this.$set(this, "chooseItemList", [...this.chooseItemList, newData]);
      }

      this.isOpenPopupChooseTicket = false;
    },
    handleRemove(value) {
      const foundIndex = this.chooseItemList.findIndex((item) => item.id === value);

      const cloneChooseItem = JSON.parse(JSON.stringify(this.chooseItemList));
      cloneChooseItem.splice(foundIndex, 1);

      this.$set(this, "chooseItemList", cloneChooseItem);
    },
    openGroupTicketDetail() {
      this.isOpenPopupChooseTicket = false;
      this.isOpenGroupTicketDetail = true;
    },
    handleHideGroupTicketDetail() {
      this.isOpenPopupChooseTicket = true;
    },
    handleHideThanTaiBanSo() {
      if (this.isViewOnlyTicketLikeLuckyNumber == false) this.isViewAllTicket = true;
    },
    initPusher() {
      this.pusherChannels.forEach((channel) => {
        channel.bind("notification", this.handleUpdateTicketQuantity);
      });
    },
    cleanPusher() {
      this.listChannel.forEach((channel) => {
        this.pusher.unsubscribe(channel);
      });
    },
    // channel: [Array]
    pusherSub(channels) {
      channels.forEach((channel) => {
        this.pusherChannels.push(this.pusher.subscribe(channel));
      });
    },
    handleUpdateTicketQuantity({ message: data }) {
      // data: Array [[id, amount], [id, amount],...]
      try {
        if (data.length) {
          const cloneTicketRetailList = JSON.parse(JSON.stringify(this.ticketRetailList));
          const cloneTicketGroupList = JSON.parse(JSON.stringify(this.ticketGroupList));
          const cloneChooseItemList = JSON.parse(JSON.stringify(this.chooseItemList));
          let isChangeChooseItem = false;
          data.forEach(([ticketId, quantity]) => {
            const foundRetailIndex = cloneTicketRetailList.findIndex((item) => item.id === ticketId);

            if (foundRetailIndex >= 0) {
              cloneTicketRetailList[foundRetailIndex].remain_amount = quantity;
            } else {
              const foundGroupIndex = cloneTicketGroupList.findIndex((item) => {
                return item.tickets.some((tk) => tk.id === ticketId);
              });

              if (foundGroupIndex >= 0) {
                cloneTicketGroupList.splice(foundGroupIndex, 1);
              }
            }

            const foundChooseItemIndex = cloneChooseItemList.findIndex((item) => {
              if (item.value) {
                return item.tickets.some((tk) => tk.id === ticketId);
              } else {
                return item.id === ticketId;
              }
            });

            if (foundChooseItemIndex >= 0) {
              if (
                cloneChooseItemList[foundChooseItemIndex].quantity > quantity ||
                cloneChooseItemList[foundChooseItemIndex].quantity === 0
              ) {
                // TH: Vé nguyên bị xé lẻ
                if (cloneChooseItemList[foundChooseItemIndex].value) {
                  cloneChooseItemList[foundChooseItemIndex].quantity = 0;
                } else {
                  cloneChooseItemList[foundChooseItemIndex].quantity = quantity;
                }

                cloneChooseItemList[foundChooseItemIndex].isChanged = true;

                isChangeChooseItem = true;
              }

              cloneChooseItemList[foundChooseItemIndex].remain_amount = quantity;
            }
          });

          this.$set(this, "ticketGroupList", cloneTicketGroupList);
          this.$set(this, "ticketRetailList", cloneTicketRetailList);
          this.$set(this, "chooseItemList", cloneChooseItemList);

          if (isChangeChooseItem) {
            this.$emit("handleCloseConfirmPayment");
            this.isOpenNotiUpdateTicket = true;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async clearExpiredPeriod() {
      await this.getListPeriod();

      const cloneChooseItemList = JSON.parse(JSON.stringify(this.chooseItemList));

      const result = cloneChooseItemList.filter((f) => {
        const timestamp = new CustomDate(f.lotteryTime).getTime();
        let now = CustomDate.now();

        return now <= timestamp - (this.gameData.metadata.count_down || 0) * 1000 - 1000;
      });
      this.$set(this, "chooseItemList", result);
    },
    handleLoadImg() {
      this.isLoadingImg = false;
    }
  },
  mounted() {
    this.getInitData();
    this.pusherSub(this.listChannel);
    this.initPusher();
  },
  unmounted() {
    this.cleanPusher();
  },
  watch: {
    getCheckLoginAfterBuyGame: function(newVal, oldVal) {
      if (this.$route.path === this.gameUrl[this.gameId].url && newVal !== oldVal && newVal) {
        this.handleBuyNow();
      }
    },
    getTraditionalTimer(newVal) {
      if (parseInt(newVal) === 0 && newVal > 0) {
        const { periodList } = this;
        Noti.fire({
          icon: "info",
          title: `Kỳ ngày ${this.formatDate(periodList?.[0]?.value || "")} đã quá hạn`,
          text: "Vui lòng chọn kỳ kế tiếp",
          showConfirmButton: false,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation"
          },
          customClass: {
            title: "sweetTitle"
          },
          timer: 3000
        });

        this.clearExpiredPeriod();
      }
    },
    periodActived() {
      this.getTicketList();
    },
    indexProductActive() {
      this.getTicketList();
    },
    listChannel(value) {
      this.cleanPusher();
      this.pusherSub(value);
      this.initPusher();
    }
  }
};
</script>

<style lang="scss" scoped>
.customInput {
  height: 50px;
  font-size: 15px;
  background-color: #fff;
  padding-left: 35px;
  padding-right: 35px;
  border: none;
  &:focus {
    border-color: #efefef;
  }
}

.groupProduct {
  display: grid;

  grid-gap: 10px;

  &__item {
    width: 100%;
    text-align: center;
    height: 40px;
    border-radius: 3px;
    background-color: #fff;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &.active {
      background: #00509d;
      color: #fff;
    }
  }
}
.ticketRetail,
.ticketGroup {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;

  &__item {
    width: 135px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    height: 46px;
    background: #ffffff;

    border: 1px solid #dcdcdc;
    border-radius: 3px;
    font-size: 13px;

    cursor: pointer;
    &.mobile {
      width: 168px !important;
      height: 46px !important;
    }
    &__number {
      font-size: 18px;
      font-weight: 600px;
      letter-spacing: 0.1em;
      .color-red-1 {
        font-weight: 600;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.active {
      border-color: $primaryColor;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    }
  }
}

.color-gray {
  color: #999999;
}
.btnChose {
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  white-space: nowrap;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;

  border-radius: 3px;
  background-color: #d31904;
}

.chooseItemCtn {
  padding: 10px;
  background-color: #f0f0f0;
  font-size: 16px;
  &__total {
    font-size: 18px;
    font-weight: 600;
  }

  &__info {
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.1em;
  }
}

.chooseItemList {
  &__ticket {
    margin-bottom: 10px;
    cursor: pointer;

    & > div {
      display: flex;
      height: 60px;
      background: #ffffff;
      border: 1px dashed #999999;
      border-radius: 3px;

      &:hover {
        background: #f5f5f5;
      }
    }

    &__info {
      padding: 8px 10px;
      border-right: 1px dashed #999999;
      width: 100%;
    }

    &__action {
      width: 47px;
      flex-shrink: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }
  }

  &__productName {
    font-size: 16px;
    margin-bottom: 7px;
    margin-top: 20px;
  }
}

.loadingTicket {
  height: 46px;
}
.product__name {
  width: 58px;
  height: 20px;
  left: 15px;
  top: 449px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #666666;
}

.no-thantai-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  .image {
    margin: 20px 0 20px 0;
  }
  .sorry {
    width: 69px;
    height: 25px;
    left: calc(50% - 69px / 2 + 1px);
    top: 165px;
    & > span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      text-align: center;

      color: #333333;
    }
  }

  .sorry-content {
    text-align: center;
    width: 210px;
    height: 40px;
    left: calc(50% - 183px / 2);
    & > span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      color: #333333;
    }
  }
}

.btn-back-to-viewAllTickets > button {
  width: 240px;
  height: 40px;
  left: 19px;
  bottom: 20px;
  margin-top: 25px;
  background: #00509d;
  border-radius: 3px;
  border-style: none;
  > strong {
    width: 71px;
    height: 20px;
    left: 105px;
    bottom: 30px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: #ffffff;
  }
}
.date-time {
  width: 330px;
  height: 20px;

  /* Text body */
  & > span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: #333333;
  }

  &.desktop {
    width: 290px;
    height: 20px;
    left: 20px;
    top: 58px;
    & > span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;

      color: #333333;
    }
  }
}
.thantai-container {
  display: flex;
  flex-direction: column;
  height: 290px;
  margin-bottom: 80px;
  & > img {
    display: block;
    margin: 16px auto 16px auto;
    width: 121px;
    height: 120px;
    left: 116px;
    top: 94px;
  }
}
.btn-listNumber {
  position: absolute;
  width: 305px;
  height: 40px;
  left: 20px;
  bottom: 21px;
  background: #d31904;
  border-radius: 3px;
  border-style: none;
  & > strong {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
  &.desktop {
    & > strong {
      width: 166px;
      height: 20px;
      left: 89.32px;
      bottom: 29px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      text-align: center;

      color: #ffffff;
    }
  }
}

.listNumber__flexBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;
  &.desktop {
    width: 310px;
    height: 112px;
    left: 18px;
    top: 232px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.item {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #ffe9a0;
  border: 1px solid #cecece;
  margin: auto;
  padding: 10px;
  text-align: center;
  & > strong {
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 20px;
    line-height: 27.24px;
    align-items: center;
  }
}

.luckyBtn {
  width: 720px;
  height: 40px;
  left: 139px;
  top: 292px;
  margin: 3px 0 12px 0;
  background: #f6eab9;
  &.hide {
    width: 520px;
  }
  & > img {
    height: 24px;
    width: 23px;
  }
  & > span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #d31904;
    margin-left: 10px;
  }

  &:hover {
    background-color: darken(#f6eab9, 10%);
  }
  &.mobile {
    width: 100%;
    min-height: 40px;
    height: 100%;
    border-radius: 3px;
    background-color: #f6eab9;
    top: 210px;
    left: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 12px 0 10px 0;
    padding: 0 10px 0 10px;
    font-size: 0.9375rem;
    & > img {
      height: 24px;
      width: 23px;
    }
    & > span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #d31904;
      margin-left: 10px;
    }

    &:hover {
      background-color: darken(#f6eab9, 10%);
    }
  }
}

.view-all-ticket {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  padding: 10px 18px;
  gap: 4px;

  left: 16px;
  top: 260px;
  background: #e5edf5;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  & > span {
    width: 180px;
    height: 20px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: #00509d;
  }
  &.desktop {
    width: 188px;
    left: 671px;
    top: 292px;
    margin: 3px 0 0 20px;
  }
}

.lucky-numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 30px;
  gap: 12px;
  width: 336px;
  height: 62px;
  left: 13px;
  top: 316px;
  & > div {
    & > span {
      width: 159px;
      height: 20px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #333333;
    }
  }
}

.flex-number {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  gap: 4px;

  width: 336px;
  height: 30px;
}
.numbers {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #ffe9a0;
  border: 1px solid #cecece;
  margin: auto;
  padding: 2px;
  text-align: center;
  font-weight: bold;
}
.iconSearchTicket {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.iconX {
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(10%, -45%);
  line-height: 1;
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 999px;
    background: #f3f3f3;
  }
}
.groupDetail {
  &__item {
    padding: 10px 0;
    border-bottom: 1px solid #efefef;
    letter-spacing: 0.1em;
  }

  & > div:last-child &__item {
    border-bottom: none;
  }
}

.periodList,
.searchBox {
  width: 50%;
}
.thanTaiBox {
  top: 210px;
  left: 14px;
  height: 40px;
  width: 345px;
  position: absolute;
  justify-content: center;
  background-color: #f6eab9;
}
.loadingImgCtn {
  position: absolute;
  top: 0;
  left: 0;
  .loadingImg {
    width: 100%;
    height: 175px;
  }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
  .periodList,
  .searchBox {
    width: 100%;
  }

  .customInput {
    height: 40px;
  }

  .ticketRetail,
  .ticketGroup {
    grid-template-columns: repeat(2, 1fr);

    &__item {
    }
  }
}
</style>

<style lang="scss">
.customBtnDropdown {
  padding: 10px 15px !important;
}
.popupThanTaiBanSo {
  .modal-content {
    position: absolute;
    width: 345px;
    height: 432px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 5px;
  }
  &.mobile {
    position: absolute;
    width: 242px;
    height: 432px;
    background: #ffffff;
    border-radius: 5px;
  }
}
.popupKhongCoVeThanTaiBanSo {
  .modal-content {
    position: absolute;
    width: 280px;
    height: 315px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 5px;
  }
  .modal-body {
    padding-bottom: 20px !important;
  }
}
.popupChooseticket {
  max-width: 420px !important;
  .modal-body {
    padding-bottom: 20px !important;
  }
}

.popupGroupTicketDetail {
  max-width: 330px !important;
  .modal-body {
    padding-bottom: 20px !important;
  }

  .modalTitle {
    color: #333333;
  }
}

.customMaxGameBox {
  .box__title {
    flex-wrap: nowrap;
    align-items: center;
    &__action {
      width: auto;
    }
  }
}

.popupTicketChoose {
  max-width: 330px !important;

  .modalTitle {
    color: #333333;
  }
}

.popupUpdateTicket {
  max-width: 420px !important;

  .modalTitle {
    color: #333333;
  }
}
</style>
