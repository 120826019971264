<template>
  <div class="text-left">
    <div v-if="chooseListLength">
      <div v-for="(data, index) in Object.values(chooseItemListFormat)" :key="data.productId" class="chooseItemList">
        <div
          class="chooseItemList__productName"
          :class="{
            'mt-0': index === 0
          }"
        >
          {{ data.productName }}
        </div>
        <div
          class="chooseItemList__ticket"
          v-for="ticket in data.ticketList"
          :key="ticket.id"
          @click="$emit('handleClickItem', ticket, !!ticket.value)"
          :class="{
            outOfStock: !ticket.quantity
          }"
        >
          <div v-if="!ticket.value">
            <div class="chooseItemList__ticket__info">
              <div>
                <span class="font-18 font-weight-600">
                  {{ ticket.ticket_info }}
                </span>
                <span class="font-13">
                  (<strong
                    :class="{
                      'color-red-1': ticket.isChanged
                    }"
                    >{{ ticket.quantity }}</strong
                  >
                  vé)
                </span>
              </div>
              <div class="font-13">
                <em>
                  {{ getDay(ticket.lotteryTime) }}
                  , ngày
                  {{ formatDate(ticket.lotteryTime) }}
                </em>
              </div>
              <div class="font-13">
                <em class="color-red-1" v-if="!ticket.quantity">
                  Vé bạn chọn đã bán hết
                </em>
                <em class="color-red-1" v-else-if="ticket.isChanged">
                  Số lượng vé bạn chọn đã thay đổi
                </em>
              </div>
            </div>
            <div class="chooseItemList__ticket__action" @click.stop="$emit('handleRemove', ticket.id)">
              <img src="@/assets/img/img_trash.png" alt="no ticket" />
            </div>
          </div>
          <div v-else>
            <div class="chooseItemList__ticket__info">
              <div>
                <span class="font-18 font-weight-600">
                  <span><span class="color-gray">x</span>{{ ticket.value.slice(1) }}</span>
                </span>
                <span class="font-13">
                  (Cặp nguyên,
                  <strong
                    :class="{
                      'color-red-1': ticket.isChanged
                    }"
                    >{{ ticket.quantity }}</strong
                  >
                  vé)
                </span>
              </div>
              <div class="font-13">
                <em>
                  {{ getDay(ticket.lotteryTime) }}
                  , ngày
                  {{ formatDate(ticket.lotteryTime) }}
                </em>
              </div>
              <div class="font-13">
                <em class="color-red-1" v-if="!ticket.quantity">
                  Vé bạn chọn đã bán hết
                </em>
                <em class="color-red-1" v-else-if="ticket.isChanged">
                  Số lượng vé bạn chọn đã thay đổi
                </em>
              </div>
            </div>
            <div class="chooseItemList__ticket__action" @click.stop="$emit('handleRemove', ticket.id)">
              <img :draggable="false" src="@/assets/img/img_trash.png" alt="no ticket" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center pt-3">
      <img :draggable="false" src="@/assets/img/image_no_ticket.png" alt="no ticket" />
      <div>Bạn chưa chọn vé</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListTicketChoose',
  props: {
    numberTicketPayment: {
      type: Number,
      default: 0
    },
    chooseItemListFormat: {
      type: Object
    },
    handleClickItem: {
      type: Function
    },
    handleRemove: {
      type: Function
    }
  },
  computed: {
    chooseListLength() {
      if (!this.chooseItemListFormat) return 0;
      return Object.values(this.chooseItemListFormat)?.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.chooseItemList {
  &__ticket {
    margin-bottom: 10px;
    cursor: pointer;

    & > div {
      display: flex;
      min-height: 60px;
      background: #ffffff;
      border: 1px dashed #999999;
      border-radius: 3px;

      &:hover {
        background: #f5f5f5;
      }
    }

    &__info {
      padding: 8px 10px;
      border-right: 1px dashed #999999;
      width: 100%;
    }

    &__action {
      width: 47px;
      flex-shrink: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }

    &.outOfStock > div {
      background-color: #ececec;
    }
  }

  &__productName {
    font-size: 16px;
    margin-bottom: 7px;
    margin-top: 20px;
  }
}
</style>
