<template>
  <div class="box">
    <div class="box__title pb-2">
      <div class="box__title__text">
        {{ title }}
      </div>
      <div v-if="isHasAction" class="box__title__action">
        <slot name="rightAction" />
      </div>
    </div>
    <slot />
    <div v-if="isHaveBottomSlot">
      <slot name="bottomSlot" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '---'
    }
  },
  computed: {
    isHasAction() {
      return !!this.$slots['rightAction'];
    },
    isHaveBottomSlot() {
      return !!this.$slots['bottomSlot'];
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  padding: 10px 0;
  &__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__text {
      font-size: 1.125rem;
      color: #666666;
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    &__action {
      flex-grow: 1;
      width: 100%;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .box {
    &__title {
      &__text {
        font-size: 1.25rem;
      }

      &__action {
        width: auto;
      }
    }
  }
}
</style>
