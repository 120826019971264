<template>
  <div class="mDropdown">
    <div class="d-flex position-relative align-items-center">
      <a @click="openModal('dropdownModal')" :class="`btnDropdown pointer w-100 globalBorder ${customBtnDropdown}`">
        <slot v-if="isHasSlotCustomItemActive" name="customItemActive" />
        <span v-else>{{ itemActived.value }}</span>
      </a>
      <div class="modals " :class="{ active: isOpen }">
        <div tabindex="0" class="modal-inner" @blur="closeModal()" :ref="'dropdownModal'">
          <div class="empty-notify text-center" v-if="data && data.length == 0">
            <p>Chưa có dữ liệu</p>
          </div>
          <div v-else>
            <div v-for="item in data" :key="item.key">
              <div
                @click="item.isActive && handlerItem(item)"
                class="d-flex align-items-center boxItemNotify"
                :class="{ 'text-muted': !item.isActive }"
              >
                <div class="py-1">
                  <p class="color-black text-truncate">
                    <strong>{{ getDay(item.value) }}</strong
                    >, ngày
                    <strong>{{ formatDate(item.value) }}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array
    },
    itemActived: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    customBtnDropdown: {
      type: String,
      default: ''
    }
  },
  computed: {
    isHasSlotCustomItemActive() {
      return !!this.$slots['customItemActive'];
    },
    isHasSlotCustomItem() {
      return !!this.$slots['customItem'];
    }
  },
  methods: {
    openModal(e) {
      if (this.isLoading) return;
      this.$emit('updateModal', true);
      this.$refs[e].focus();
    },
    closeModal() {
      this.$emit('updateModal', false);
    },
    handlerItem(obj) {
      this.$emit('updateModal', false);
      this.$emit('updateData', obj);
    }
  }
};
</script>

<style lang="scss" scoped>
.btnDropdown {
  color: #333333;
  padding: 10px 15px;
  background-color: #fff;
  position: relative;
  display: flex;
  border-radius: 3px;
  align-items: center;
  transition: 0.3s all;
  height: 40px;
  // font-weight: 600;
  //   &:hover {
  //     background-color: #d9d9d9;
  //   }
  &:after {
    content: '';
    position: absolute;
    right: 16px;
    display: inline-block;
    margin-top: 0;
    width: 8px;
    height: 8px;
    border-top: 2px solid #333 !important;
    border-right: 2px solid #333;
    border-left: none;
    transform: rotate(135deg) translate(0%, 100%);
    top: 50%;
    right: 10px;
  }
}
.modals {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  transform: scale(0);
  transition: ease opacity 0.3s, ease transform 0s 0.3s;

  &.active {
    opacity: 1;
    transform: scale(1);
    transition: ease opacity 0.3s, ease transform 0s;
  }
}

.modal-inner {
  // height: 401px;
  position: absolute;
  right: 0;
  top: 43px;
  outline: none;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #efefef;
  background-color: #ffffff;
  div {
    .boxItemNotify {
      // font-weight: 600;
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
  .boxItemNotify {
    overflow: hidden;
    padding: 0.4rem 1rem;
    border-bottom: 1px solid #efefef;
    transition: 0.3s all;
    cursor: pointer;
    &:not(.text-muted):hover {
      background-color: #f7f7f7;
    }
    &.text-muted {
      cursor: not-allowed;
    }
  }
  .boxAll {
    transition: 0.3s all;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &:hover {
      background-color: #f7f7f7;
    }
  }
}

@media screen and (min-width: 576.5px) {
  .btnDropdown {
    height: 50px;
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .modal-inner {
    width: 100% !important;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .btnDropdown {
    padding: 0.9rem 1.5rem;
    // &:after {
    //   right: 26px;

    //   top: 14px !important;
    // }
  }
}
</style>
