<template>
  <div class="buttonQuantity">
    <b-btn
      small
      class="buttonQuantity__action buttonQuantity__action--decrease"
      :disabled="quantity === 1 || isBlock"
      @click="quantity--"
      elevation="0"
    >
      <span class="mx-2">-</span>
    </b-btn>
    <span
      class="buttonQuantity__quantity"
      :class="{
        disabled: isBlock
      }"
    >
      {{ quantity }}
    </span>
    <b-btn
      :disabled="(max && quantity === max) || isBlock"
      small
      class="buttonQuantity__action buttonQuantity__action--increase"
      @click="quantity++"
      elevation="0"
    >
      <span class="mx-2">+</span>
    </b-btn>
  </div>
</template>

<script>
export default {
  name: 'ButtonQuantity',
  props: {
    value: {
      type: Number,
      default: 1
    },
    max: {
      type: Number
    },
    isBlock: {
      type: Boolean
    }
  },
  computed: {
    quantity: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.buttonQuantity {
  display: inline-flex;
  height: 44px;
  border-radius: 3px;
  // border: solid 1px #cecece;
  align-items: center;
  justify-content: space-between;
  &__action {
    min-width: 41px !important;
    width: 41px !important;
    height: 41px !important;
    border-radius: 3px;
    background-color: #e5edf5 !important;
    padding: 0 !important;
    color: #00509d;
    border: none;
    font-size: 40px;
    line-height: 1;

    &--decrease {
      font-size: 60px;
      line-height: 0;

      span {
        position: relative;
        top: -5px;
        left: 0;
      }
    }
  }

  &__quantity {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: center;
    height: 100%;
    background: #ffffff;
    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 3px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    min-width: 60px;
    &.disabled {
      background-color: #f0f0f0;
    }
  }
}
</style>
