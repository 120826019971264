<template>
  <div class="container">
    <img draggable="false" src="../../../assets/img/thantai-sad.png" />
    <p v-if="customText" class="text-center mt-3">
      Thần Tài đang tạm ngưng để cập nhật kho vé số {{ gameName }}.<br />Vui lòng quay lại sau ít phút.
    </p>
    <p v-else class="text-center mt-3">
      Rất tiếc, hiện tại hệ thống mua hộ vé {{ gameName }} đang bảo trì.<br />Vui lòng quay lại sau ít phút.
    </p>
    <router-link :to="'/'" class="btn btnConfirm text-uppercase font-weight-600 mt-3">
      Quay về trang chủ
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    gameName: {
      type: String
    },
    customText: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  margin-top: 60px;
  margin-bottom: 80px;
  align-items: center;
  flex-direction: column;
  p {
    color: #666666;
    font-size: 15px;
  }
  img {
    width: 196px;
    height: 196px;
  }
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    img {
      width: 136px;
      height: 136px;
    }
  }
}
</style>
