var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addToCardBox"},[(_vm.isHasSlotTop)?_vm._t("slotTop"):_vm._e(),_c('GroupGameInfoPayment',{staticClass:"d-none d-lg-block w-100 mb-3",attrs:{"totalPrice":_vm.totalPrice,"fee":_vm.transactionFee,"resultPrice":_vm.resultPrice}}),_vm._t("default"),_c('div',{staticClass:"d-flex d-lg-none justify-content-between mb-2 pb-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"addToCardBox__priceTitle"},[_vm._v("Tổng cộng: ")]),_c('span',{staticClass:"addToCardBox__priceValue"},[_vm._v(_vm._s(_vm.formatPrice(_vm.resultPrice)))])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('ExcludeFeatureWrapper',{attrs:{"featureName":_vm.FEATURE_HANDLER.DEPOSIT_BALANCE}},[(_vm.accountId)?_c('div',{staticClass:"totalBalance"},[_vm._v(" Số dư TK: "),_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.depositBalance + _vm.pendingBalance)))])]):_vm._e()])],1)]),(_vm.isPaymentIntegration)?_c('div',{staticClass:"addToCardBox__action"},[_c('button',{staticClass:"btn w-100 addToCardBox__action--buy mr-0",attrs:{"disabled":_vm.loggedIn ? (_vm.isLoading && !_vm.lastClickAddCart) || (_vm.isDisabled && _vm.isBuyNow) : _vm.isDisabled || _vm.isDisabledQuickBuy},on:{"click":_vm.handleBuyNowInPaymentIntegration}},[(_vm.isLoading && !_vm.lastClickAddCart)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.isBuyNow ? _vm.buyNowText : 'Xem giỏ hàng'))])]),(!_vm.hiddenQuickBuy)?_c('p',{staticClass:"font-14 mt-1"},[_c('i',[_vm._v("Quét mã, thanh toán ngay, không cần đăng nhập")])]):_vm._e()]):_c('div',{staticClass:"addToCardBox__action"},[_c('div',{staticClass:"d-flex"},[(!_vm.isHideAddToCartByExclude && !_vm.isHideAddtoCardBtn)?_c('button',{staticClass:"btn addToCardBox__action--add",class:{
          disablePlayTrial8: _vm.isPlayTrialModeMobile
        },attrs:{"disabled":(_vm.isLoading && _vm.lastClickAddCart) || _vm.isDisabled},on:{"click":_vm.addToCart}},[(_vm.isLoading && _vm.lastClickAddCart)?_c('div',{staticClass:"text-center w-100 d-flex justify-content-center align-items-center h-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[(_vm.stateAddedCart)?_c('span',{staticClass:"text-uppercase  w-100 color-yellow d-flex align-items-center justify-content-center addToCardBox__action__textAdded"},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon_checkdone.svg"),"width":"20","height":"20"}})]):_c('span',{staticClass:"groupCartIcon"},[_c('img',{attrs:{"src":require("@/assets/img/icon_addcart.svg"),"alt":"icon_add_to_cart","width":"22px","height":"21px"}})])]),_c('img',{staticClass:"imgGame",class:{
            active: _vm.runAnimateAddToCart
          },attrs:{"id":"iconCartFrom","src":_vm.gameImg ? _vm.gameImg : '@/assets/img/icon_addcart.svg',"alt":"icon_add_to_cart","width":"22px","height":"21px"}})]):_vm._e(),(_vm.isPlaytrial && _vm.isBuyNow)?_c('Tooltip',{attrs:{"dataStep":"choose_buy_now","content":"Nhấn nút <b>Mua Vé</b> để tiến hành thanh toán cho cho các bộ số đã chọn.","position":"top"}},[_c('button',{staticClass:"btn w-100 addToCardBox__action--buy",class:{
            'mr-0': _vm.isHideAddToCartByExclude || _vm.isHideAddtoCardBtn
          },attrs:{"disabled":(_vm.isLoading && !_vm.lastClickAddCart) || (_vm.isDisabled && _vm.isBuyNow)},on:{"click":_vm.buyNow}},[(_vm.isLoading && !_vm.lastClickAddCart)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.buyNowText))])])]):_c('button',{staticClass:"btn w-100 addToCardBox__action--buy",class:{
          'mr-0': _vm.isHideAddToCartByExclude || _vm.isHideAddtoCardBtn
        },attrs:{"disabled":(_vm.isLoading && !_vm.lastClickAddCart) || (_vm.isDisabled && _vm.isBuyNow)},on:{"click":_vm.buyNow}},[(_vm.isLoading && !_vm.lastClickAddCart)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.isBuyNow ? _vm.buyNowText : 'Xem giỏ hàng'))])])],1),_c('ExcludeFeatureWrapper',{staticClass:"w-100",attrs:{"featureName":_vm.FEATURE_HANDLER.QUICK_BUY}},[(!_vm.hiddenQuickBuy)?_c('button',{staticClass:"btn w-100 addToCardBox__action--quickBuy mt-2",attrs:{"disabled":_vm.isDisabled || _vm.isDisabledQuickBuy},on:{"click":_vm.handleQuickBuy}},[_c('div',[_c('svg',{staticClass:"dropShadow mr-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('g',{attrs:{"transform":"translate(13970 19621)"}},[_c('rect',{attrs:{"width":"24","height":"24","transform":"translate(-13970 -19621)","fill":"#fff","opacity":"0"}}),_c('path',{attrs:{"d":"M1.071,24a5.483,5.483,0,0,1,.56-1.575q.638-1.5,1.431-3.305t1.457-3.37a6.724,6.724,0,0,0,.612-1.718q-.052-.13-.833-.468t-1.7-.729q-.924-.39-1.7-.768T0,11.4q-.078-.26,1.379-2.108t3.253-3.93Q6.432,3.283,7.968,1.6T9.607,0a5.657,5.657,0,0,1-.56,1.6Q8.41,3.126,7.616,4.948T6.159,8.305a6.8,6.8,0,0,0-.612,1.666,2.709,2.709,0,0,0,.833.429l1.718.716q.937.39,1.731.794t.846.69q.052.286-1.405,2.134T6.029,18.65q-1.783,2.069-3.318,3.735A9.248,9.248,0,0,1,1.071,24Z","transform":"translate(-13963 -19621)","fill":"#fff"}})])]),_c('span',[_vm._v("Mua siêu tốc")])])]):_vm._e(),(!_vm.hiddenQuickBuy)?_c('p',{staticClass:"font-14 mt-1"},[_c('i',[_vm._v("Quét mã, thanh toán ngay, không cần đăng nhập")])]):_vm._e()])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }