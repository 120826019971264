<template>
  <div class="row d-none d-lg-flex align-items-center">
    <div class="col-8">
      <div class="d-flex align-items-center mb-1 mt-3">
        <h4 class="mb-0 mr-3">{{ gameTitle }}</h4>
        <div
          class="d-inline-flex align-items-center pointer"
          @click="$refs.modalTutorialGame.handleOpenModalTutorial(true)"
        >
          <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 16 16">
            <path
              d="M-4.8-11.172a8,8,0,0,0-8,8,8,8,0,0,0,8,8,8,8,0,0,0,8-8A8,8,0,0,0-4.8-11.172Zm0,14.452a6.448,6.448,0,0,1-6.452-6.452A6.449,6.449,0,0,1-4.8-9.623,6.449,6.449,0,0,1,1.655-3.172,6.448,6.448,0,0,1-4.8,3.28Zm3.459-8.232A3.2,3.2,0,0,0-4.61-7.817,3.626,3.626,0,0,0-7.854-6.069a.388.388,0,0,0,.085.528l.9.681a.387.387,0,0,0,.538-.069c.485-.613.831-.967,1.579-.967.574,0,1.283.369,1.283.926,0,.421-.347.637-.914.954-.661.37-1.535.831-1.535,1.984v.279a.387.387,0,0,0,.387.387h1.472a.387.387,0,0,0,.387-.387v-.2C-3.674-2.756-1.337-2.79-1.337-4.952ZM-3.442.441A1.356,1.356,0,0,0-4.8-.914,1.356,1.356,0,0,0-6.152.441,1.356,1.356,0,0,0-4.8,1.8,1.356,1.356,0,0,0-3.442.441Z"
              transform="translate(12.797 11.172)"
              fill="#00509d"
            />
          </svg>
          <span class="color-blue text-uppercase ">Hướng dẫn</span>
        </div>
      </div>
    </div>
    <div v-if="isBuyTogetherDetail" class="col-4 d-flex justify-content-end">
      <div @click="$router.back()" class="pointer d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <g transform="translate(-1095 -280)">
            <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
            <path
              d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
              transform="translate(1096.98 290.313)"
              fill="#666"
            />
          </g>
        </svg>
        <span class="ml-1">
          Chọn nhóm bao khác
        </span>
      </div>
    </div>

    <!-- <div class="col-6 text-right">
      <div
        class="d-inline-flex align-items-center pointer"
        @click="$refs.modalTutorialGame.handleOpenModalTutorial(true)"
      >
        <span class="color-blue text-uppercase mr-2">Hướng dẫn</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 48 48">
          <g transform="translate(-798 -398)">
            <rect width="48" height="48" transform="translate(798 398)" fill="#fff" opacity="0" />
            <g transform="translate(49 -40)">
              <rect width="48" height="48" transform="translate(749 438)" fill="#00509d" opacity="0" />
              <path
                d="M24.7-35.575a20.906,20.906,0,0,1,20.9,20.9,20.9,20.9,0,0,1-20.9,20.9,20.9,20.9,0,0,1-20.9-20.9A20.9,20.9,0,0,1,24.7-35.575Zm0-3.1a24,24,0,0,0-24,24,24,24,0,0,0,24,24,24,24,0,0,0,24-24A24,24,0,0,0,24.7-38.672ZM21.219-5.381A1.161,1.161,0,0,0,20.058-4.22v.774a1.161,1.161,0,0,0,1.161,1.161h6.968a1.161,1.161,0,0,0,1.161-1.161V-4.22a1.161,1.161,0,0,0-1.161-1.161H27.026V-18.93a1.161,1.161,0,0,0-1.161-1.161H21.219a1.161,1.161,0,0,0-1.161,1.161v.774a1.161,1.161,0,0,0,1.161,1.161h1.161V-5.381ZM24.7-28.607a3.1,3.1,0,0,0-3.1,3.1,3.1,3.1,0,0,0,3.1,3.1,3.1,3.1,0,0,0,3.1-3.1A3.1,3.1,0,0,0,24.7-28.607Z"
                transform="translate(748.297 476.672)"
                fill="#00509d"
              />
            </g>
          </g>
        </svg>
      </div>
    </div> -->
    <div class="col-12">
      <hr class="mt-0" />
    </div>
    <ModalTutorialGame ref="modalTutorialGame" :tutorialGame="tutorialGame" />
  </div>
</template>

<script>
import ModalTutorialGame from './ModalTutorialGame';

export default {
  components: { ModalTutorialGame },
  props: {
    gameTitle: {
      type: String
    },
    tutorialGame: {
      type: String
    }
  },

  computed: {
    isBuyTogetherDetail() {
      return this.$route.meta.isBuyTogetherDetail;
    }
  }
};
</script>
<style lang="scss" scoped>
h4 {
  font-size: 1.5625rem;
}
</style>
